import {store} from "@app/client-store";
import {
  selectFormulaForSelectedCell,
  selectors,
  selectUiDatasourceForSelectedCell,
} from "@features/templates/state/selectors";
import {updateTemplatesViewState} from "@features/templates/state/slice";
import {attemptToCloseBracketsAndParentheses} from "@shared/data-functions/formula/formula-utilities";
import {runErrorCheckingForDatasourceDiff} from "@shared/lib/alert-utilities";
import {getChangesForInsertFormulaForDates} from "@shared/lib/datasource-utilities";
import {time, timeEnd} from "@shared/lib/debug-provider";
import {getUniqueSlug} from "@shared/lib/slug";
import {applyDatasourceChanges} from "@state/datasources/slice";
import {selectScenarioId} from "@state/global/slice";
import {upsertRow} from "@state/template-rows/slice";

import {closeTooltip, setActiveItemIndex} from "./Tooltip/slice";
import {substituteSuggestionInFormula} from "./Tooltip/suggestion-utilities";

import type {RefObject} from "react";

import {optimisticUpdate} from "@/lib/optimistic-update";
import {handlePastedData} from "@/lib/row-utilities-client";

export const makeHandlePaste =
  (handleFormulaChange: (formulaStr: string) => void) => (evt: React.ClipboardEvent<HTMLInputElement>) => {
    const inputElement = evt.target as HTMLInputElement;
    const currentVal = inputElement.value;

    const values = handlePastedData(evt);
    const firstValAsString = values[0]?.[0]?.toString() ?? "";

    if (firstValAsString) {
      const startPos = inputElement.selectionStart ?? 0;
      const endPos = inputElement.selectionEnd ?? 0;

      // Split the current value into two parts based on the selection (or cursor position)
      const beforeCursor = currentVal.slice(0, Math.max(0, startPos));
      const afterCursor = currentVal.slice(Math.max(0, endPos));

      // Concatenate the value to be pasted between these two parts
      const newVal = beforeCursor + firstValAsString + afterCursor;

      evt.stopPropagation();
      evt.preventDefault();
      handleFormulaChange(newVal);
    }
  };

export const handleFormulaBlurStateUpdates = (evt?: React.FocusEvent<HTMLInputElement>) => {
  const state = store.getState();
  const dispatch = store.dispatch;

  const cellBeingEdited = selectors.cellBeingEdited(state);

  const scenarioId = selectScenarioId(state);
  const {column, departmentId, vendor} = selectors.activeCell(state);

  const activeDatasourceRange = selectUiDatasourceForSelectedCell(state);
  const activeDatasourceFormula = selectFormulaForSelectedCell(state);

  const formulaType = activeDatasourceRange?.type === "text" ? "plainText" : "formula";

  if (!cellBeingEdited || !scenarioId) return;

  const rowIdsByName = state.templateRows.idsByName;
  const row = state.templateRows.entities[cellBeingEdited.rowId];

  if (!row) return;

  // console.log({cellBeingEdited, row, activeDatasourceFormula, formulaType, column, departmentId, vendor});

  // If dataType is text it means it's the row name as it's the only data of type text editable for now
  if (formulaType === "plainText") {
    if (row.display_name !== cellBeingEdited.formula) {
      const newSlug = getUniqueSlug(cellBeingEdited.formula, rowIdsByName, row.name).slug;

      dispatch(upsertRow({...row, display_name: cellBeingEdited.formula, name: newSlug}));
    }
  } else if (activeDatasourceFormula !== cellBeingEdited.formula) {
    const dateKeyToFormulaMapping = {
      [cellBeingEdited.dateKey]: cellBeingEdited.formula,
    };

    const datasourceDiff = getChangesForInsertFormulaForDates({
      row,
      scenarioId,
      dateKeyToFormulaMapping,
      state,
      departmentId,
      vendor,
    });

    // Check for ref and formula errors, and update the state accordingly
    runErrorCheckingForDatasourceDiff(datasourceDiff, state, dispatch);

    // time("FormulaTextEditor", "Calculated optimistic values");
    // optimisticUpdate({
    //   scenarioId,
    //   dispatch,
    //   datasourceDiff,
    // });
    // timeEnd("FormulaTextEditor", "Calculated optimistic values");

    requestIdleCallback(() => {
      time("FormulaTextEditor", "Calculated optimistic values");
      optimisticUpdate({
        scenarioId,
        dispatch,
        datasourceDiff,
      });
      timeEnd("FormulaTextEditor", "Calculated optimistic values");
      dispatch(
        applyDatasourceChanges({
          datasourceDiff,
          // optimisticDiff,
          reason: `Edited formula for ${row.name}, ${column}`,
          // sendOptimisticUpdate: true,
        }),
      );
    });
  }

  evt?.preventDefault();
  evt?.stopPropagation();

  dispatch(
    updateTemplatesViewState({
      cellBeingEdited: null,
      caller: "handleFormulaBlurStateUpdates->after applying datasource changes",
      callStack: new Error().stack,
    }),
  );
  // setTooltipOpen(false);
};

const keysToPropagateOutsideFormulaEditor = ["ArrowUp", "ArrowDown", "Tab", "Enter", "Escape"];
const eventsToPropagateToTooltip = ["ArrowUp", "ArrowDown", "Tab", "Enter", "Escape"];

export const keyDownHandler =
  (
    component: "formulaBar" | "cell",
    element: "formulaWrapperInsideTooltip" | "formulaInput" | "formulaInputWrapper",
    onFormulaChange: (newFormula: string, tooltipOpenOverride?: boolean) => void,
    formulaInputRef: RefObject<HTMLInputElement>,
  ): React.KeyboardEventHandler =>
  (e) => {
    /***** Gather the necessary data to make decisions *****/
    const state = store.getState();
    const dispatch = store.dispatch;

    // If the formula is not being edited in this component, don't handle the event
    if (state.templatesView.cellBeingEdited?.component !== component) return;
    const {activeItemIndex, suggestionsResult, isOpen} = state.tooltip;

    const tooltipOpen = isOpen && state.templatesView.activeCell?.column !== "name";

    /***** Initialize the outputs *****/
    let preventDefault = false;
    let stopPropagation = false;

    /***** Handle the keydown event for the formula input *****/
    switch (element) {
      case "formulaInput": {
        if (keysToPropagateOutsideFormulaEditor.includes(e.key)) {
          preventDefault = true;
        }
        // console.log(`Key down event on formula input:`, e.key, tooltipOpen);
        if (tooltipOpen && eventsToPropagateToTooltip.includes(e.key)) {
          preventDefault = true;
        } else {
          if (e.key === "Escape") {
            dispatch(
              updateTemplatesViewState({
                cellBeingEdited: null,
                caller: "keyDownHandler -> formulaInput keydown handler -> Escape key",
              }),
            );
            preventDefault = true;
            stopPropagation = true;
          }
        }

        break;
      }
      case "formulaInputWrapper": {
        // console.log(`Handling keydown event on formula input wrapper: `, e.key, tooltipOpen);
        /*** Handle the keydown event for the formula input wrapper when the tooltip is open ***/
        if (tooltipOpen) {
          stopPropagation = true;
          const suggestions = suggestionsResult?.suggestions ?? [];
          switch (e.key) {
            case "ArrowUp": {
              let newIndex = (activeItemIndex ?? suggestions.length) - 1;
              if (newIndex < 0) newIndex = suggestions.length > 1 ? 1 : 0;
              dispatch(setActiveItemIndex(newIndex));
              preventDefault = true;
              break;
            }
            case "ArrowDown": {
              let newIndex = (activeItemIndex ?? -1) + 1;
              if (newIndex >= suggestions.length) newIndex = 0;
              dispatch(setActiveItemIndex(newIndex));
              preventDefault = true;
              break;
            }
            case "Tab":
            case "Enter": {
              if (!suggestions.length) return;
              handleSuggestionSelect(onFormulaChange);
              preventDefault = true;
              break;
            }
            case "Escape": {
              dispatch(closeTooltip());
              preventDefault = true;
              break;
            }
          }
        } else {
          // console.log(`Key down event on formula input wrapper (tooltip closed): `, e.key, e.target);
          if (e.key === "Enter" || e.key === "Tab") {
            if (e.key === "Enter") {
              stopPropagation = true;

              // Try to close unclosed brackets and parentheses
              const formula = state.templatesView.cellBeingEdited?.formula ?? null;
              if (formula) {
                const newFormula = attemptToCloseBracketsAndParentheses(formula);

                if (newFormula !== formula) {
                  dispatch(
                    updateTemplatesViewState({
                      cellBeingEdited: {...state.templatesView.cellBeingEdited, formula: newFormula},
                    }),
                  );
                }
              }
            }

            formulaInputRef.current?.blur();
          }
        }

        if (!keysToPropagateOutsideFormulaEditor.includes(e.key)) {
          stopPropagation = true;
        }

        break;
      }
    }

    /***** Prevent the default behavior and stop the propagation of the event if necessary *****/
    if (preventDefault) e.preventDefault();
    if (stopPropagation) e.stopPropagation();
  };

export function handleSuggestionSelect(
  onFormulaChange: (newFormula: string, tooltipOpenOverride?: boolean) => void,
  i?: number,
) {
  const state = store.getState();
  const {suggestionsResult, activeItemIndex} = state.tooltip;
  const formula = selectors.cellBeingEdited(state)?.formula;
  if (!suggestionsResult || suggestionsResult.chunkIndex === null) return;

  const newFormula = substituteSuggestionInFormula(
    formula ?? null,
    suggestionsResult.chunks ?? [],
    suggestionsResult.chunkIndex,
    suggestionsResult.suggestions?.[i ?? activeItemIndex ?? 0] ?? "",
  );

  onFormulaChange(newFormula, false);

  setTimeout(() => {
    store.dispatch(closeTooltip());
  }, 15);
}
