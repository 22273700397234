import {parseCacheKey} from "@shared/data-functions/cache/cache-utilities";
import {makeDependencyKeyHumanReadable} from "@shared/data-functions/cache/dependency-cache-debug-utilities";
import {findCircularDependenciesForKey2024} from "@shared/data-functions/cache/dependency-cache-utilities";
import {getRefStrsFromFormula, validateFormulaThroughParser} from "@shared/data-functions/formula/formula-utilities";
import {findFormulaDsErrors} from "@shared/lib/datasource-utilities";
import {clearAlertsForDatasource, upsertAlertsForDs} from "@state/alerts/slice";
import dayjs from "dayjs";

import {getDatasourcesForMonth, type PartialDsError} from "./datasource-utilities";
import {time, timeEnd} from "./debug-provider";
import {mapEntitiesToIds} from "./entity-functions";
import id from "./id";

import type {DependencyCache} from "@shared/data-functions/cache/dependency-cache";
import type {DsCircularRefError, DsError, DsFormulaParseError} from "@shared/types/alerts";
import type {Datasource} from "@shared/types/datasources";
import type {AppThunkDispatch, RootState} from "@state/store";
import type {DatasourceDiff} from "./datasource-utilities";

/**
 * Given a datasourceId and an alert type to alert mapping, returns the difference between the current alerts and the new alerts
 *
 * @param datasourceId
 * @param alerts
 *
 * @example
 * setAlertsForDatasource("1", {
 *  "errorType1": {message: "Error message"},
 *  "errorType2": null,
 *  "errorType3": {message: "Error message"},
 * })
 *
 **/
function setAlertsForDatasourceAndGetDiff({
  state,
  datasourceId,
  alerts,
}: {
  state: RootState;
  datasourceId: string;
  alerts: {[errorType: string]: DsError | PartialDsError | null};
}) {}

export function checkKeysForCircularDependencies({
  dependencyCache,
  state,
  cacheKeys,
}: {
  dependencyCache: DependencyCache;
  state: RootState;
  cacheKeys: string[];
}) {
  time("checkKeysForCircularDependencies", "Checked for circular dependencies");
  const circularDependencyErrors: Record<string, DsCircularRefError> = {};

  const existingCircularDependencyErrors = mapEntitiesToIds(
    state.alerts.entities,
    state.alerts.idsByType["CIRCULAR_REF"],
  ) as DsCircularRefError[];
  const existingCircularDependencyErrorCacheKeys: string[] = existingCircularDependencyErrors.map(
    (error) => error.details.cycle[0],
  );
  const existingCircularDependencyErrorsByCacheKey = Object.fromEntries(
    existingCircularDependencyErrors.map((error) => [error.details.cycle[0], error]),
  );

  const visited: Record<string, boolean> = {};
  for (const cacheKey of cacheKeys) {
    if (visited[cacheKey]) continue;
    visited[cacheKey] = true;
    const cycle = findCircularDependenciesForKey2024(dependencyCache, cacheKey, state);
    if (!cycle) continue;

    const parsedCacheKey = parseCacheKey(cacheKey);
    const template =
      state.templates.entities[state.templateRows.entities[parsedCacheKey.rowId ?? ""]?.template_id ?? ""];
    if (!template) {
      console.error(`Found circular dependency but couldn't find template for rowId ${parsedCacheKey.rowId}`, cycle);
      continue;
    }
    const datasourceForCacheKey = getDatasourcesForMonth(state.datasources, parseCacheKey(cacheKey), template.options);
    if (!datasourceForCacheKey[0]) continue;

    const humanReadableCacheKey = makeDependencyKeyHumanReadable(cacheKey, state, false, true);
    const humanReadableCycle = cycle.map((cycleKey) => makeDependencyKeyHumanReadable(cycleKey, state, false, true));
    console.log({datasourceId: datasourceForCacheKey[0]?.id, humanReadableCacheKey, humanReadableCycle});

    const existingError = existingCircularDependencyErrorsByCacheKey[cacheKey];
    if (existingError) {
      circularDependencyErrors[existingError.id] = {
        ...existingError,
        datasource_id: datasourceForCacheKey[0].id,
        details: {
          ...existingError.details,
          cycle,
        },
      };
    } else {
      const cycleError: DsCircularRefError = {
        type: "CIRCULAR_REF",
        created_at: new Date().toISOString(),
        datasource_id: datasourceForCacheKey[0]?.id,
        details: {
          cycle,
        },
        id: id(),
      };
      circularDependencyErrors[cycleError.id] = cycleError;
    }

    break;
  }

  const removedErrors: DsCircularRefError[] = [];
  const errorsByFirstCycleElement = Object.fromEntries(
    Object.values(circularDependencyErrors).map((error) => [error.details.cycle[0], error]),
  );
  // For any existing circular dependency errors that are no longer circular, remove them
  for (const existingError of existingCircularDependencyErrors) {
    if (!errorsByFirstCycleElement[existingError.details.cycle[0]]) {
      removedErrors.push(existingError);
    }
  }

  timeEnd("checkKeysForCircularDependencies", "Checked for circular dependencies");

  return {upserts: Object.values(circularDependencyErrors), deletes: removedErrors};
}

export function runErrorCheckingForDatasourceDiff(
  datasourceDiff: DatasourceDiff<Datasource>,
  state: RootState,
  dispatch: AppThunkDispatch,
) {
  const refAndFormulaErrorsByDsId: {[dsId: string]: PartialDsError[]} = {};
  const errorsToUpsert: PartialDsError[] = [];
  if (datasourceDiff.upserts.length || datasourceDiff.deletes.length) {
    const refAndFormulaErrors: PartialDsError[] = [];
    for (const datasource of datasourceDiff.upserts) {
      refAndFormulaErrorsByDsId[datasource.id] ||= [];
      if (datasource.type === "integration") continue;
      const refStrs = datasource.options.formula?.length
        ? getRefStrsFromFormula(datasource.options.formula, false, false)
        : [];
      let errors = findFormulaDsErrors(datasource, state.templateRows.idsByName, state.departments.idsByName);
      const syntaxIsValid =
        !datasource.options.formula ||
        datasource.options.formula.length === 0 ||
        validateFormulaThroughParser(datasource.options.formula, refStrs);
      if (!syntaxIsValid) {
        errors = [
          ...errors,
          {
            type: "FORMULA_PARSE_ERROR",
            datasource_id: datasource.id,
            created_at: dayjs().toISOString(),
          } as DsFormulaParseError,
        ];
      }

      if (errors.length) {
        refAndFormulaErrors.push(...errors);
        refAndFormulaErrorsByDsId[datasource.id].push(...errors);
      }
    }

    // Remove all errors related to deleted datasources
    if (datasourceDiff.deletes.length) {
      for (const ds of datasourceDiff.deletes) {
        dispatch(clearAlertsForDatasource(ds.id));
      }
    }

    if (refAndFormulaErrors.length) {
      errorsToUpsert.push(...refAndFormulaErrors);
    }
  }

  // Clear errors for datasources in refErrorsByDsId that don't have any errors
  for (const dsId in refAndFormulaErrorsByDsId) {
    if (!refAndFormulaErrorsByDsId[dsId].length) {
      dispatch(clearAlertsForDatasource(dsId));
    }
  }

  if (errorsToUpsert.length) dispatch(upsertAlertsForDs(errorsToUpsert));

  return errorsToUpsert;
}
