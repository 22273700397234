import {createSlice} from "@reduxjs/toolkit";
import {createSyncedActionCreators} from "@shared/lib/misc";

import {initialState} from "./initial-state";

import type {PayloadAction} from "@reduxjs/toolkit";

export interface ActiveCell {
  rowId?: string | null;
  column?: string | null;
  departmentId?: string | null;
  vendor?: string | null;
  total?: boolean;
}

export interface TemplatesViewState {
  activeCell: ActiveCell;
  templateId: string | null;
  draggingFrom: string | null;
  columnDateKeys: string[];
  rowNameWidth: number;
  otherUsersSelectedCells: {
    dateKey: string;
    rowId: string;
    username: string;
    userid: string;
  }[];
  highlightedDateKeys: string[];
  cellBeingEdited: {
    rowId: string;
    dateKey: string;
    formula: string;
    originalFormula: string;
    component: "formulaBar" | "cell";
    departmentId?: string | null;
    vendor?: string | null;
    tooltipOpen?: boolean;
  } | null;
  pendingDateKeys: {rowId: string; dateKeys: string[]}[];
  createScenarioOpen: boolean;
  createScenarioProgress: null | string;
  sidebarOpen: boolean;
  collapsedRows: string[];
  cellWidth: number | null;
  draggingOverCols: string[];
  dragDirection: "left" | "right" | null;
  blueRowsPulledFromWorker: string[];
  loadingNewData: boolean;
}

const slice = createSlice({
  name: "templatesView",
  initialState,
  reducers: {
    otherUserSelectedACell: (
      state,
      action: PayloadAction<(typeof initialState)["otherUsersSelectedCells"][number] | null>,
    ) => {
      // TODO
      // 1. Allow multiple users to show up, and multiple cells per user (multiple tabs)
      // 2. Fetch user selected cells when changing templateId
      state.otherUsersSelectedCells = action.payload ? [action.payload] : [];
    },
    setTemplatesViewState: <K extends keyof TemplatesViewState>(
      state: TemplatesViewState,
      action: PayloadAction<{key: K; value: TemplatesViewState[K]}>,
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    setSelectedTemplateId: (state, action: PayloadAction<(typeof initialState)["templateId"]>) => {
      state.templateId = action.payload;
    },
    setDraggingFrom: (state, action: PayloadAction<(typeof initialState)["draggingFrom"]>) => {
      state.draggingFrom = action.payload;
    },
    setColumnDateKeys: (state, action: PayloadAction<(typeof initialState)["columnDateKeys"]>) => {
      state.columnDateKeys = action.payload;
    },
    setRowNameWidth: (state, action: PayloadAction<(typeof initialState)["rowNameWidth"]>) => {
      state.rowNameWidth = action.payload;
    },
    setHighlightedDateKeys: (state, action: PayloadAction<(typeof initialState)["highlightedDateKeys"]>) => {
      if (!state.highlightedDateKeys.length && !action.payload.length) return;
      state.highlightedDateKeys = action.payload;
    },
    addToHighlightedDateKeys: (state, action: PayloadAction<string[]>): void => {
      state.highlightedDateKeys.push(...action.payload);
    },
    toggleRowCollapsedState: (state, action: PayloadAction<string>) => {
      const collapsedEntityIndex = state.collapsedRows.indexOf(action.payload);
      if (collapsedEntityIndex > -1) {
        state.collapsedRows.splice(collapsedEntityIndex, 1);
      } else {
        state.collapsedRows.push(action.payload);
      }
    },
    toggleRowIdPendingState: (state, action: PayloadAction<{rowId: string; dateKeys: string[] | null}>) => {
      const existingPendingStateForRowIndex = state.pendingDateKeys.findIndex(
        ({rowId}) => rowId === action.payload.rowId,
      );
      if (action.payload.dateKeys === null && existingPendingStateForRowIndex > -1) {
        state.pendingDateKeys.splice(existingPendingStateForRowIndex, 1);
      } else if (action.payload.dateKeys !== null) {
        if (existingPendingStateForRowIndex > -1) {
          state.pendingDateKeys[existingPendingStateForRowIndex] = {
            rowId: action.payload.rowId,
            dateKeys: action.payload.dateKeys,
          };
        } else {
          state.pendingDateKeys.push({rowId: action.payload.rowId, dateKeys: action.payload.dateKeys});
        }
      }
    },
    updateTemplatesViewState: (
      state,
      action: PayloadAction<Partial<TemplatesViewState> & {caller?: string; callStack?: string}>,
    ) => {
      const {caller, callStack, ...rest} = action.payload;
      // console.log(`Update templates view state:`, rest, {
      //   caller,
      //   callStack,
      // });
      return {
        ...state,
        ...rest,
      };
    },
  },
});

export const {
  setSelectedTemplateId,
  setHighlightedDateKeys,
  setTemplatesViewState,
  toggleRowCollapsedState,
  setDraggingFrom,
  setColumnDateKeys,
  otherUserSelectedACell,
  updateTemplatesViewState,
  addToHighlightedDateKeys,
} = slice.actions;

export const {toggleRowIdPendingState} = createSyncedActionCreators(slice.actions);

const reducer = slice.reducer;

export default reducer;
