import colors from "@app/styles/colors";
import {baseInputStyles} from "@app/styles/general-ui";
import {getSvgColorStyles} from "@components/SVGIcon";
import color from "color";
import {createUseStyles} from "react-jss";

export const sharedValues = {
  hoverStyle: {
    background: color(colors.primary.lightGold03).alpha(0.5).rgb().string(),

    "& > span, & > a, & > div, & > a": {
      color: colors.primary.gold,
    },

    "& > $iconLeft, & > $iconRight, & > $itemElements > $iconContainer": {
      extend: getSvgColorStyles(colors.primary.gold),
    },
  },
  maxHeight: 350,
};

export default createUseStyles({
  dropdownMain: {
    display: "flex",
    userSelect: "none",
  },
  dropDownItems: {
    padding: [9, 0],
    maxHeight: sharedValues.maxHeight,
    // minHeight: 200,
    overflowY: "auto",

    "&$noScroll": {
      overflowY: "hidden",
    },
  },
  dropdownItem: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 24,
    cursor: "pointer",
    padding: [8, 15],
    color: colors.text.darkBlue01,

    "& strong": {
      fontWeight: 700,
    },

    "&$disabled": {
      color: colors.text.lightBlue02,
    },

    "&:not($disabled):hover": {
      extend: sharedValues.hoverStyle,
    },

    "& > span:last-of-type": {
      display: "inline-flex",
      flex: 1,
    },
  },
  disabled: {cursor: "default", pointerEvents: "none"},
  rightText: {
    color: colors.text.lightBlue01,
    alignSelf: "flexEnd",

    "$disabled &": {
      color: colors.text.lightBlue02,
    },
  },
  subtext: {
    fontSize: 11,
    fontWeight: 400,
    opacity: 0.6,
    fontStyle: "italic",
    marginLeft: 10,
  },
  borderTop: {
    borderTop: [1, "solid", colors.text.lightBlue04],
  },
  mainText: {
    flex: 1,
    textAlign: "left",
  },
  selected: {
    extend: sharedValues.hoverStyle,
    "& > $borderRightSelected": {
      display: "block",
    },
  },
  borderRightSelected: {
    position: "absolute",
    right: 0,
    width: 2,
    height: "100%",
    background: colors.primary.gold,
    display: "none",
  },
  defaultTrigger: {
    extend: baseInputStyles,
    display: "flex",
    width: "100%",
    cursor: "pointer",
    whiteSpace: "nowrap",
    position: "relative",
    paddingTop: 2,
    paddingRight: ((baseInputStyles.padding as [number, number])[1] || 0) + 12,
    overflow: "hidden",
    textOverflow: "ellipsis",
    userSelect: "none",

    "&:active, &:hover:active": {
      background: colors.ui.lightBlue07,
    },

    "$triggerOpen &, $triggerOpen &:hover": {
      border: [1, "solid", "transparent "],
      color: colors.primary.gold,
      background: colors.primary.lightGold06,
      extend: getSvgColorStyles(colors.primary.gold),

      "&:active": {
        background: colors.primary.lightGold04,
      },
    },

    "$disabled&": {
      color: colors.text.lightBlue02,
      cursor: "default",
      pointerEvents: "none",

      "& $caretDown": {
        extend: getSvgColorStyles(colors.ui.lightBlue02),
      },
    },

    "&:hover": {
      background: color(colors.ui.lightBlue08).alpha(0.7).rgb().string(),
      border: [1, "solid", "transparent"],
      // boxShadow: getBorderAsBoxShadow("all", 1, "transparent"),
    },
  },
  triggerOpen: {},
  caretDown: {
    position: "absolute",
    right: 12,
    marginBottom: 3,
    paddingLeft: 10,
    extend: getSvgColorStyles(colors.ui.lightBlue01),
  },
  separator: {
    marginLeft: 12,
    width: 1.5,
    backgroundColor: colors.ui.lightBlue04,
    height: 15,
  },
  small: {},
  medium: {height: 40},
  large: {},
  iconBase: {
    width: 15,
    height: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 6,
    extend: getSvgColorStyles(colors.ui.lightBlue01),
  },
  iconLeft: {
    extend: "iconBase",

    "$disabled &": {
      extend: getSvgColorStyles(colors.text.lightBlue02),
    },
  },
  iconRight: {
    extend: "iconBase",
    marginLeft: 6,
    width: 24,
    height: 24,

    "&  svg": {
      width: "100%",
      height: "100%",
    },

    "$disabled &": {
      opacity: 0.5,
    },
  },

  search: {padding: 15},
  noMatchingItems: {
    width: "100%",
    textAlign: "center",
    color: colors.text.lightBlue01,
    fontSize: 13,
    fontWeight: 500,
    paddingBottom: 15,
  },
  noScroll: {},
});
