import {useAppSelector} from "@app/hooks";
import {useCallback, useEffect, useRef} from "react";

import {selectDatasourcesForSelectedRow, selectSelectedRow} from "./selectors";

import type {FormulaDatasource} from "@shared/types/datasources";

export const useSelectedRow = () => {
  const row = useAppSelector(selectSelectedRow);
  return row;
};

export const useSelectedRowDatasources = () => {
  return useAppSelector(selectDatasourcesForSelectedRow);
};

export const useSelectRowAutoForecastDs = () => {
  const datasources = useSelectedRowDatasources();
  return datasources.find(
    (datasource) =>
      datasource.type === "formula" && datasource.start === "1f" && datasource.options.ui?.type === "auto",
  ) as FormulaDatasource | undefined;
};

export function useDebounce<F extends (...args: any[]) => any>(fn: F, delay: number): F {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFn = useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        fn(...args);
      }, delay);
    },
    [fn, delay],
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return debouncedFn as F;
}
