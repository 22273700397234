import {store} from "@app/client-store";
import {useAppDispatch, useAppSelector} from "@app/hooks";
import {askForConfirmation} from "@app/ui-state/slice";
import DatePicker from "@components/Datepicker/RDP";
import FormElement from "@components/FormElement";
import {SectionCol} from "@components/RightSidebar/Section";
import RoundButton from "@components/RoundButton";
import TextInputDropdownCombo from "@components/TextInputDropdownCombo";
import {
  getDatasourceChangesAfterFcUpdate,
  getFcDatasourceStartForDisplay,
  getForecastDatesForEmployee,
  getFormulaFromFcUiOptions,
  getUpsertsAfterFcDatasourceRemoval,
} from "@shared/data-functions/hiring-plan/fc-datasource-utilities";
import {getDatasourceDiff, insertDatasources} from "@shared/lib/datasource-utilities";
import {getFormattedFullDate} from "@shared/lib/date-utilities";
import {applyDatasourceChanges} from "@shared/state/datasources/slice";
import {selectFcByName} from "@shared/state/financial-components/selectors";
import {selectScenarioId, selectVersionLocked} from "@shared/state/global/slice";
import {selectTemplateByName} from "@shared/state/templates/selectors";
import {removeCbTx} from "@state/cb-tx/slice";
import {selectEmployeeFcDatasourcesSorted} from "@state/datasources/selectors";
import {removeRow} from "@state/template-rows/slice";
import {projKey} from "@state/utils";

import {getValueForDisplay} from "../utilities";
import {expressedAsItems} from "./CompensationAndTaxes";
import useStyles from "./CompensationAndTaxes.jss";

import type {HiringPlanFormulaDataProvider, HiringPlanFormulaDatasource} from "@shared/types/datasources";
import type {HiringPlanTemplateRow} from "@shared/types/db";
import type {Employee} from "@shared/types/hiring-plan";

type UiOptionsKeys = keyof HiringPlanFormulaDataProvider["options"]["ui"];

interface CompensationRangeProps {
  datasource: HiringPlanFormulaDatasource;
  fcRow: HiringPlanTemplateRow;
  employee: Employee;
  rangeIndex: number;
}

export default function CompensationRange({datasource, fcRow, employee, rangeIndex}: CompensationRangeProps) {
  const fc = useAppSelector((state) => selectFcByName(state, fcRow.options.fc_name));
  const hiringPlanTemplate = useAppSelector((state) => selectTemplateByName(state, "hiring_plan"));
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const isVersionLocked = useAppSelector(selectVersionLocked);

  const scenarioId = useAppSelector(selectScenarioId);
  const scenarioProperties = employee.scenario_properties[scenarioId || ""];
  const hireDate = scenarioProperties?.hire_date;
  const termDate = scenarioProperties?.term_date;

  const sortedFcDatasources = useAppSelector((state) =>
    selectEmployeeFcDatasourcesSorted(state, {
      employeeId: employee.id,
      fcName: fc?.name ?? "",
      scenarioId,
    }),
  );

  if (!fc || !scenarioId || !scenarioProperties || !hiringPlanTemplate || !hireDate) return null;

  const {datepickerDisabled, displayStartDate} = getFcDatasourceStartForDisplay({
    datasource,
    hiringPlanTemplate,
    hireDate,
  });

  // resolve previous datasource to be able to set the min date on the datepicker
  const index = sortedFcDatasources.indexOf(datasource);
  const previousDatasource = sortedFcDatasources[index - 1];
  const datepickerMinDate = previousDatasource
    ? getFcDatasourceStartForDisplay({
        datasource: previousDatasource,
        hiringPlanTemplate,
        hireDate,
      }).displayStartDate
    : getFormattedFullDate(hireDate, "start", "MM/DD/YYYY");

  const handleRangeTextInputUpdate =
    (property: "start" | UiOptionsKeys): React.ChangeEventHandler<HTMLInputElement> =>
    (evt) => {
      let {value} = evt.target;
      if (property === "value") value = value.replaceAll(/ |,/g, "");
      handleRangeUpdate(property, value);
    };

  const handleRangeUpdate = <T extends "start" | UiOptionsKeys>(
    property: T,
    value: T extends UiOptionsKeys ? HiringPlanFormulaDataProvider["options"]["ui"][T] : string,
  ) => {
    const state = store.getState();
    const updatedDatasource = {
      ...datasource,
    };

    if (property === "start") {
      updatedDatasource.start = value ?? null;
    } else {
      updatedDatasource.options = {...datasource.options, ui: {...datasource.options.ui, [property]: value}};
      updatedDatasource.options.formula = getFormulaFromFcUiOptions(updatedDatasource.options.ui);
    }

    const rowDatasources = selectEmployeeFcDatasourcesSorted(state, {
      employeeId: employee.id,
      fcName: fc.name,
      scenarioId,
    }) as HiringPlanFormulaDatasource[];

    const {upserts, deletes} = getDatasourceChangesAfterFcUpdate({
      datasources: rowDatasources,
      updatedDatasource,
      templateOptions: hiringPlanTemplate.options,
      employee,
    });

    const datasourceDiff = getDatasourceDiff(rowDatasources, upserts, state);

    dispatch(applyDatasourceChanges({datasourceDiff, reason: `Updated ${fc.display_name} range`}));
  };

  const handleDeleteRange = () => {
    dispatch(
      askForConfirmation({
        preset: "delete",
        actionButtonIcon: "trash",
        text: `Are you sure you want to delete the change in <b>${fc.display_name}</b>?`,
        onConfirm: () => {
          const state = store.getState();
          const rowDatasources = selectEmployeeFcDatasourcesSorted(state, {
            employeeId: employee.id,
            fcName: fc.name,
            scenarioId,
          }) as HiringPlanFormulaDatasource[];

          const upserts = getUpsertsAfterFcDatasourceRemoval(rowDatasources, datasource);
          const forecastDates = getForecastDatesForEmployee({
            hiringPlanTemplateOptions: hiringPlanTemplate.options,
            employee,
            scenarioId,
          });

          const resultWithInsertDatasources = insertDatasources({
            existingDatasources: rowDatasources,
            datasourcesToAdd: upserts,
            forecastDates,
            extendToFillGaps: true,
            method: upserts.length ? "replace" : undefined,
          });

          const datasourceDiff = getDatasourceDiff(rowDatasources, resultWithInsertDatasources.datasources, state);
          dispatch(applyDatasourceChanges({datasourceDiff, reason: `Deleted ${fc.display_name} range`}));

          // Check if the row is now completely empty in all scenarios. If it is, delete it
          const freshState = store.getState();
          if (!freshState.datasources.idsByRowId[fcRow.id]?.length) {
            const cbTxIdsToDelete = state.cbTx.idsByRowId[projKey(fcRow.id, scenarioId)];
            if (cbTxIdsToDelete) dispatch(removeCbTx(cbTxIdsToDelete));
            dispatch(removeRow(fcRow.id));
          }
        },
      }),
    );
  };

  return (
    <div className={styles.rangeWrapper}>
      <SectionCol key={`${datasource.end}${datasource.start}`}>
        <FormElement
          label="Amount"
          tooltip="HiringPlan::Employees::Sidebar::CompensationAmount"
          tooltipVariables={{
            fcName: fc.display_name || "compensation",
          }}
        >
          <TextInputDropdownCombo
            textInputProps={{
              formatter: (value) => getValueForDisplay(value, datasource.options.ui.type),
              changeTrigger: "blur",
              onChange: handleRangeTextInputUpdate("value"),
              value: datasource.options.ui.value,
              disabled: isVersionLocked,
            }}
            dropdownProps={{
              items: expressedAsItems,
              onSelect: (item) => handleRangeUpdate("expressedAs", item.key),
              selectedKey: datasource.options.ui.expressedAs,
              disabled: isVersionLocked,
            }}
          />
        </FormElement>
      </SectionCol>
      <SectionCol small className={styles.compensationDate}>
        <div className={styles.dateContainer}>
          <FormElement label="Start" tooltip="HiringPlan::Employees::Sidebar::CompensationStart">
            <DatePicker
              onChange={(date) => handleRangeUpdate("start", date || "")}
              value={displayStartDate}
              min={datepickerMinDate}
              max={getFormattedFullDate(termDate ?? hiringPlanTemplate.options.end, "end")}
              disabled={isVersionLocked || datepickerDisabled}
              clearable={false}
            />
          </FormElement>
        </div>
      </SectionCol>
      {!isVersionLocked ? (
        <FormElement label="&nbsp;" className={styles.removeRangeIconWrapper}>
          <RoundButton
            className={styles.deleteRangeButton}
            icon="cross"
            onClick={handleDeleteRange}
            enableCssStates
            iconSize={20}
          />
        </FormElement>
      ) : null}
    </div>
  );
}
