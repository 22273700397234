import {store} from "@app/client-store";
import {exportTemplateAllData} from "@features/shared-views/Navigation/exports/templates-all-data";
import {exportTemplateCurrentView} from "@features/shared-views/Navigation/exports/templates-current-view";
import {getEmptyRow} from "@shared/lib/templates-utilities";
import {addRows, upsertRowsLocal} from "@shared/state/template-rows/slice";
import {fireActionInWorker} from "@shared/worker-fn-gateway";
import {matchRoutes} from "react-router-dom";

import {selectSelectedRow} from "./state/selectors";
import {updateTemplatesViewState} from "./state/slice";
import DatePickerTab from "./TemplateOptions/DatePickerTab";

import type {DropdownItem} from "@components/Dropdown";
import type {MenuElement} from "@components/Menu";
import type {BreadcrumbItem} from "@features/shared-views/Navigation/BreadcrumbItem";
import type {BreadcrumbsProvider} from "@features/shared-views/Navigation/hooks";
import type {TopBarMenuElement} from "@features/shared-views/Navigation/NotificationsArea";

import {routes} from "@/Router";

export const useTemplatesBcItems: BreadcrumbsProvider = ({location}) => {
  const state = store.getState();
  const matches = matchRoutes(routes, location);

  const breadcrumbs: BreadcrumbItem[] = [
    {
      text: "Worksheets",
    },
  ];

  if (matches?.[0]?.params.templateName) {
    const template = Object.values(state.templates.entities).find(
      (item) => item?.name === matches[0].params.templateName,
    );
    if (template) breadcrumbs.push({text: template.display_name});
  }

  return breadcrumbs;
};

const statementsOrder = ["profit_and_loss", "balance_sheet", "cash_flow_statement"];

export const useFinancialStatementsBcItems: BreadcrumbsProvider = ({searchParams, setSearchParams, templates}) => {
  const breadcrumbs: BreadcrumbItem[] = [
    {
      text: "Financial Statements",
    },
  ];

  const statements: DropdownItem[] = templates
    .filter((template) => template.type === "financial_statement")
    .toSorted((a, b) => statementsOrder.indexOf(a.name) - statementsOrder.indexOf(b.name))
    .map((template) => ({
      key: template.name,
      value: template.display_name,
    }));

  let selectedSecondLevelItemIndex = statements.findIndex((item) => searchParams.get("statement") === item.key);
  if (selectedSecondLevelItemIndex === -1) selectedSecondLevelItemIndex = 0;

  const secondLevelItem: BreadcrumbItem = {
    text: statements[selectedSecondLevelItemIndex].value as string,
    dropdownItems: statements.map((item, index) => ({...item, selected: selectedSecondLevelItemIndex === index})),
    onDropdownChange: (item: DropdownItem) => {
      if (item.key === null) return;
      searchParams.set("statement", item.key);
      setSearchParams(searchParams);
    },
  };

  breadcrumbs.push(secondLevelItem);

  return breadcrumbs;
};

export function getSettingsItems(pathname: string): TopBarMenuElement[] {
  const elements: TopBarMenuElement[] = [
    // {
    //   text: "Edit Columns",
    //   disabled: true,
    //   type: "item",
    // },
    {
      text: "Display Time Period",
      type: "submenu",
      icon: "calendar",
      // @ts-ignore
      content: DatePickerTab,
      viewOnlyEnabled: true,
    },
    // {
    //   text: "Export as CSV",
    //   icon: "export",
    //   type: "item",
    //   viewOnlyEnabled: true,
    //   onClick: () => exportTemplateCurrentView({templateId: store.getState().templatesView.templateId!, format: "csv"}),
    //   // onClick: () => exportTemplateAllData({templateId: store.getState().templatesView.templateId!, format: "csv"}),
    // },
    // {
    // type: "submenu",
    // text: "Export...",
    // icon: "export",
    // content: [
    {
      type: "item",
      text: "Export All Data as CSV",
      icon: "export",
      viewOnlyEnabled: true,
      onClick: () => exportTemplateAllData({templateId: store.getState().templatesView.templateId!, format: "csv"}),
    },
    {
      type: "item",
      text: "Export Current View as CSV",
      icon: "export",
      viewOnlyEnabled: true,
      onClick: () => exportTemplateCurrentView({templateId: store.getState().templatesView.templateId!, format: "csv"}),
    },
    // ],
    // },
    // {
    //   text: "Edit Teams",
    //   selected: pathname.split("/")[2] === "teams",
    //   type: "item",
    //   icon: "team",
    //   link: `/hiring-plan/teams`,
    // },
  ];

  return elements;
}

export function getNewMenuElements(pathname: string): {elements: MenuElement[]; onChange: (item: MenuElement) => void} {
  const onChange = async (item: MenuElement) => {
    const state = store.getState();
    const {templateId, activeCell} = state.templatesView;
    const template = state.templates.entities[templateId || ""];
    if (!templateId || !template) return;
    const newRow = getEmptyRow({
      templateRowsState: state.templateRows,
      templateId,
    });
    const selectedRow = selectSelectedRow(state);
    let index: number | undefined = template.ordering.length;
    if (item.text.includes("row")) {
      if (item.text === "Add 1 row above") {
        index = template.ordering.findIndex((ordering) => ordering.rowId === activeCell.rowId);
        newRow.formatting.indentation = selectedRow?.formatting.indentation;
      } else if (item.text === "Add 1 row below") {
        index = template.ordering.findIndex((ordering) => ordering.rowId === activeCell.rowId) + 1;
        newRow.formatting.indentation = selectedRow?.formatting.indentation;
      }

      await store.dispatch(
        addRows({
          templateId,
          rows: [newRow],
          index,
        }),
      );
    } else if (item.text === "Scenario") {
      await store.dispatch(updateTemplatesViewState({createScenarioOpen: true}));
    }

    await fireActionInWorker(upsertRowsLocal([newRow]));

    setTimeout(() => {
      store.dispatch(
        updateTemplatesViewState({
          activeCell: {rowId: newRow.id, column: "name"},
          // cellBeingEdited: {rowId: newRow.id, dateKey: "name", component: "cell", formula: ""},
        }),
      );
    }, 1);
  };

  const menuElements: MenuElement[] = [];

  if (!pathname.includes("financial-statements")) {
    menuElements.push(
      {
        type: "item",
        text: "Add 1 row above",
        icon: "addRowAbove",
      },
      {
        type: "item",
        text: "Add 1 row below",
        icon: "addRowBelow",
      },
      {
        type: "item",
        text: "Add row",
        icon: "addRow",
      },
      {type: "separator", text: ""},
      {type: "item", text: "Scenario", icon: "scenario"},
    );
  }

  return {
    onChange,
    elements: menuElements,
  };
}

export default {
  useTemplatesBcItems,
  useFinancialStatementsBcItems,
  getNewMenuElements,
  getSettingsItems,
};
