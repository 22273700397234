import Popover from "@components/Popover";
import SVGIcon from "@components/SVGIcon";
import SearchBar from "@components/SearchBar";
import clsx from "clsx";
import {useLayoutEffect, useRef, useState} from "react";

import useStyles from "./styles.jss";

import type {IButton} from "@components/Button";
import type {PopoverGenericProps} from "@components/Popover";
import type {IconNames} from "@components/SVGIcon";

/**
 * DropdownItem
 *
 * Represents an individual item within the Dropdown component. Each item can have various properties which define its appearance and behavior in the dropdown list.
 *
 * @typedef {Object} DropdownItem
 * @property {string | number} key - Unique identifier for the dropdown item. Can be a string or number.
 * @property {string | number} value - Display value for the dropdown item.
 * @property {boolean} [disabled] - If true, the item is disabled and cannot be selected.
 * @property {boolean} [selected] - Indicates if the item is currently selected.
 * @property {string} [rightText] - Optional text to display on the right side of the item.
 * @property {string} [subtext] - Optional subtext to display below the main text.
 * @property {number} [indentation] - Optional indentation level for the item, used for visual hierarchy.
 * @property {IconNames} [iconLeft] - Optional icon to display on the left side of the item.
 * @property {IconNames} [iconRight] - Optional icon to display on the right side of the item.
 * @property {boolean} [borderTop] - If true, displays a top border on the item for visual separation.
 *
 * @example
 * const dropdownItem: DropdownItem = {
 *   key: "1",
 *   value: "Option 1",
 *   subtext: "Additional info",
 *   iconLeft: "checkmark",
 *   iconRight: "arrow",
 * };
 */
export type DropdownItem = {
  key: string | null;
  value: string | number;
  disabled?: boolean;
  selected?: boolean;
  rightText?: string;
  subtext?: string;
  indentation?: number;
  iconLeft?: IconNames;
  iconRight?: IconNames;
  borderTop?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  customElement?: JSX.Element;
};

/**
 * DropdownSelectHandler
 *
 * This is a type definition for a callback function used in the Dropdown component. It defines the behavior when an item in the dropdown is selected.
 *
 * @callback DropdownSelectHandler
 * @param {T} item - The selected item from the dropdown. The item is of type T, which extends the base DropdownItem type.
 * @returns {void} - This function does not return a value.
 *
 * @example
 * const handleSelect: DropdownSelectHandler<ExtendedDropdownItem> = (item) => {
 *   console.log("Selected item:", item);
 * };
 */

export type DropdownSelectHandler<T extends DropdownItem = DropdownItem> = (item: T) => void;

type BaseProps<T extends DropdownItem = DropdownItem> = {
  items: readonly T[];
  onSelect: DropdownSelectHandler<T>;
  selectedKey?: string | null;
  disabled?: boolean;
  showSearch?: boolean;
};

export type DropdownWithTriggerProps<T extends DropdownItem = DropdownItem> = BaseProps<T> & PopoverGenericProps;
export type DropdownWithoutTriggerProps<T extends DropdownItem = DropdownItem> = BaseProps<T> &
  Partial<PopoverGenericProps> & {
    buttonSize?: IButton["size"];
    buttonWidth?: number;
    buttonBold?: boolean;
    buttonFill?: boolean;
    buttonRightText?: string;
    text?: string;
    rightText?: string;
  };

/**
 * Dropdown component
 *
 * This component displays a list of items in a dropdown menu, which can be either triggered by a custom component or by default trigger logic.
 * It supports features like search, custom rendering of items, and various customizations through props.
 *
 * The component is an overloaded function, supporting two different prop structures:
 * 1. DropdownWithTriggerProps: For use with a custom trigger component.
 * 2. DropdownWithoutTriggerProps: For use with default trigger logic, with additional properties for customization.
 *
 * @param {DropdownWithTriggerProps<T> | DropdownWithoutTriggerProps<T>} props - The props for the component.
 *    - `items`: The list of items to display in the dropdown.
 *    - `onSelect`: Callback function that is called when an item is selected.
 *    - `selectedKey`: The key of the initially selected item.
 *    - `disabled`: Boolean to disable the dropdown.
 *    - `showSearch`: Boolean to enable a search bar within the dropdown.
 *    - `buttonSize`, `buttonWidth`, `buttonBold`, `buttonFill`, `buttonRightText`, `text`, `rightText`: Various styling and text props for the default trigger.
 *    - `popoverProps`: Props for customizing the popover component.
 *    - `children`: Custom trigger component, only used in `DropdownWithTriggerProps`.
 *
 * @returns {JSX.Element} The rendered Dropdown component.
 *
 * @example
 * // Example with custom trigger
 * <Dropdown
 *   items={[{ key: "1", value: "Item 1" }, { key: "2", value: "Item 2" }]}
 *   onSelect={(item) => console.log(item)}
 *   selectedKey="1"
 *   {...otherProps}
 * >
 *   <CustomTriggerComponent />
 * </Dropdown>
 *
 * @example
 * // Example with default trigger
 * <Dropdown
 *   items={[{ key: "1", value: "Item 1" }, { key: "2", value: "Item 2" }]}
 *   onSelect={(item) => console.log(item)}
 *   selectedKey="1"
 *   buttonSize="large"
 *   {...otherProps}
 * />
 */
function Dropdown<T extends DropdownItem = DropdownItem>(props: DropdownWithTriggerProps<T>): JSX.Element;
function Dropdown<T extends DropdownItem = DropdownItem>(props: DropdownWithoutTriggerProps<T>): JSX.Element;
function Dropdown<T extends DropdownItem = DropdownItem>({
  onSelect,
  items,
  children: trigger,
  text,
  selectedKey,
  buttonSize = "normal",
  buttonWidth,
  buttonBold = true,
  buttonFill = false,
  buttonRightText,
  disabled = false,
  showSearch = false,
  ...popoverProps
}: BaseProps<T> & Partial<DropdownWithoutTriggerProps<T>> & Partial<DropdownWithTriggerProps<T>>) {
  const styles = useStyles();
  const selectedItemRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState("");

  const handleItemClick = (e: React.MouseEvent, item: T) => {
    if (item.disabled) return;
    if (item.onClick) {
      item.onClick(e);
    } else {
      onSelect(item);
    }
  };

  const selectedItem = items.find((item) => item.selected || item.key === selectedKey) || null;

  if (!trigger) {
    trigger = (
      <DefaultDropdownTrigger
        disabled={disabled}
        text={text?.length ? text : selectedItem?.value.toString() ?? ""}
        width={buttonWidth}
        fill={buttonFill}
        rightText={buttonRightText}
        iconLeft={selectedItem?.iconLeft}
        iconRight={selectedItem?.iconRight}
        className={popoverProps.openTriggerClassName}
      />
    );
  }

  const handleSearchChange = (text: string) => {
    setSearch(text.toLowerCase());
  };

  const filteredItems = !search
    ? items
    : items.filter(
        (item) =>
          item.value.toString().toLowerCase().includes(search) ||
          item.key?.toString().toLowerCase().includes(search) ||
          item.subtext?.toString().toLowerCase().includes(search) ||
          item.rightText?.toString().toLowerCase().includes(search),
      );

  return (
    <Popover
      position={["bottom", "top"]}
      {...popoverProps}
      closeOnClick
      disableSelection
      width={popoverProps.width || "matchTrigger"}
      openTriggerClassName={clsx(styles.triggerOpen, popoverProps.openTriggerClassName)}
      isOpen={disabled ? false : undefined}
      content={
        <>
          {showSearch ? (
            <div className={styles.search} onClick={(evt) => evt.stopPropagation()}>
              <SearchBar onSearchChange={handleSearchChange} value={search} leftMargin={false} clearable />
            </div>
          ) : null}
          <div className={styles.dropDownItems}>
            {!filteredItems?.length && search ? (
              <div className={styles.noMatchingItems}>No matching items</div>
            ) : (
              filteredItems.map((item) => (
                <div key={item.key} ref={selectedItem?.key === item.key ? selectedItemRef : null}>
                  {item.customElement ?? (
                    <DropdownItemComponent
                      item={item}
                      onClick={handleItemClick}
                      selected={selectedItem?.key === item.key}
                      search={search}
                    />
                  )}
                </div>
              ))
            )}
          </div>
        </>
      }
    >
      {trigger}
    </Popover>
  );
}

function DropdownItemComponent<T extends DropdownItem>({
  item,
  onClick,
  selected = false,
  search,
}: {
  readonly item: T;
  readonly onClick: (e: React.MouseEvent, item: T) => void;
  selected?: boolean;
  search: string;
}) {
  const styles = useStyles();
  const selectedItemRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (selectedItemRef?.current) selectedItemRef.current.scrollIntoView({block: "center"});
  }, []);

  return (
    <div
      ref={selected ? selectedItemRef : null}
      className={clsx(styles.dropdownItem, {
        [styles.disabled]: item.disabled,
        [styles.selected]: selected,
        [styles.borderTop]: item.borderTop,
      })}
      style={{paddingLeft: 15 + (item.indentation || 0) * 10}}
      onClick={(e) => onClick(e, item)}
    >
      {item.iconLeft ? (
        <div className={styles.iconLeft}>
          <SVGIcon name={item.iconLeft} />
        </div>
      ) : null}
      <span>{search.length ? <TextWithHighlight text={item.value.toString()} highlight={search} /> : item.value}</span>
      {item.subtext && (
        <span className={styles.subtext}>
          {search.length ? <TextWithHighlight text={item.subtext.toString()} highlight={search} /> : item.subtext}
        </span>
      )}
      {item.iconRight ? (
        <div className={styles.iconRight}>
          <SVGIcon name={item.iconRight} />
        </div>
      ) : null}
      {item.rightText ? (
        <div className={styles.rightText}>
          {search.length ? <TextWithHighlight text={item.rightText} highlight={search} /> : item.rightText}
        </div>
      ) : null}
      <div className={styles.borderRightSelected} />
    </div>
  );
}

function TextWithHighlight({text, highlight}: {text: string; highlight: string}) {
  const highlightStartIndex = text.toLowerCase().indexOf(highlight);
  if (highlightStartIndex === -1) return <span>{text}</span>;
  const before = text.slice(0, highlightStartIndex);
  const highlightedText = text.slice(highlightStartIndex, highlightStartIndex + highlight.length - 1);
  const after = text.slice(highlightStartIndex + highlight.length - 1);

  return (
    <span>
      <span>{before}</span>
      <strong>{highlightedText}</strong>
      <span>{after}</span>
    </span>
  );
}

type DefaultDropdownTriggerProps = {
  disabled?: boolean;
  fill?: boolean;
  rightText?: string;
  text: string;
  width?: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  iconLeft?: IconNames;
  iconRight?: IconNames;
  className?: string;
  textClassName?: string;
};

export function DefaultDropdownTrigger({
  disabled = false,
  rightText,
  text,
  onClick,
  width,
  iconLeft,
  iconRight,
  className,
  textClassName,
}: DefaultDropdownTriggerProps) {
  const styles = useStyles();
  const classes = clsx(className, styles.defaultTrigger, {[styles.disabled]: disabled});
  const textClasses = clsx(textClassName, styles.mainText);
  const style: React.CSSProperties = {};
  if (width) style.width = width;

  return (
    <div className={classes} style={{width}} onClick={disabled ? () => null : onClick}>
      {iconLeft ? (
        <div className={styles.iconLeft}>
          <SVGIcon name={iconLeft} />
        </div>
      ) : null}
      <span className={textClasses}>{text}</span>
      {rightText ? (
        <>
          <span className={styles.rightText}>{rightText}</span>
          <span className={styles.separator} />
        </>
      ) : null}
      {iconRight ? (
        <div className={styles.iconRight}>
          <SVGIcon name={iconRight} />
        </div>
      ) : null}
      <div className={styles.caretDown}>
        <SVGIcon name="dropdown" />
      </div>
    </div>
  );
}

export default Dropdown;
