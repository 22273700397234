import {store} from "@app/client-store";
import {getDepartmentsWithDepth} from "@shared/lib/department-utilities";
import {fireActionInWorker} from "@shared/worker-fn-gateway";
import {setGlobalStatePropertyValue} from "@state/global/slice";
import {generateMonthlyCache} from "@state/transaction-items/slice";
import memoize from "fast-memoize";

import type {DropdownItem} from "@components/Dropdown";
import type {Department} from "@shared/types/db";
import type {AppDispatch} from "@state/store";
import type {URLSearchParamsInit} from "react-router-dom";

export const handleDropdownChange =
  (searchParams: URLSearchParams, setSearchParams: (nextInit: URLSearchParamsInit) => void, dispatch: AppDispatch) =>
  async (item: DropdownItem) => {
    const state = store.getState();
    const department = state.departments.entities[item.key || ""];

    if (item.key !== null && !department) return;

    const action = setGlobalStatePropertyValue({key: "selectedDepartmentId", value: item.key});
    dispatch(action);
    await fireActionInWorker(action);

    fireActionInWorker(
      generateMonthlyCache({
        sendToMainThread: true,
      }),
    );

    if (item.key && department) searchParams.set("department", department.name);
    if (item.value === "All Departments") searchParams.delete("department");
    setSearchParams(searchParams);
  };

export const getDepartmentDropdownItems = memoize(
  (departments: Department[], selectedDepartmentId: string | null, allDepartmentsCustomText?: string) => {
    const departmentsWithDepth = getDepartmentsWithDepth(departments, true);

    const dropdownItems: DropdownItem[] = [];

    for (const {depth, entity: department} of departmentsWithDepth) {
      dropdownItems.push({
        key: department?.id ?? null,
        value: department?.display_name ?? (allDepartmentsCustomText || "All Departments"),
        selected: (department?.id ?? null) === selectedDepartmentId,
        indentation: depth,
      });
    }

    return dropdownItems;
  },
);
