import {getForecastTypeForRow} from "@shared/lib/row-utilities";

import type {IconNames} from "@app/IconNames";
import type {EntityId} from "@reduxjs/toolkit";
import type {FormulaDatasource} from "@shared/types/datasources";
import type {TemplateRow} from "@shared/types/db";

export const ForecastTypes = [
  {
    key: "custom",
    value: "Custom",
    iconLeft: "cog" as IconNames,
  },
  {
    key: "auto",
    value: "Automatic",
    iconLeft: "sync" as IconNames,
  },
] as const;

export const formulaTypes = [
  {
    key: "average",
    value: "Average",
    iconLeft: "formula" as IconNames,
  },
  {
    key: "median",
    value: "Median",
    iconLeft: "formula" as IconNames,
  },
  {
    key: "pct-of-target",
    value: "% of Row",
    iconLeft: "formula" as IconNames,
  },
] as const;

export const formulaTypeToLabelMapping = Object.fromEntries(formulaTypes.map((item) => [item.key, item.value]));

export function getFormulaSidebarRowInfos({
  row,
  autoForecastDatasourceFormula,
  departmentId,
  vendor,
}: {
  row: TemplateRow;
  autoForecastDatasourceFormula: FormulaDatasource | undefined;
  departmentId?: EntityId | null;
  vendor?: string | null;
}) {
  const currentForecastType = getForecastTypeForRow(row, departmentId ?? null);
  const forecastTypeSummary =
    row.type === "account" && row.options.forecastType === "department" ? "By Department" : "By Row";
  const formulaTypeSummary = !autoForecastDatasourceFormula
    ? "Custom"
    : formulaTypeToLabelMapping[autoForecastDatasourceFormula.options.ui?.formulaType ?? "average"];
  const rowCanBeForecasted =
    (currentForecastType === "department" && departmentId && !vendor) ||
    (currentForecastType === "vendor" && vendor) ||
    (currentForecastType === "row" && !departmentId && !vendor);
  const forecastGranularityHidden =
    row.type !== "account" || !!vendor || (row.options.forecastType === "row" && !!departmentId);
  return {
    forecastTypeSummary,
    formulaTypeSummary,
    rowCanBeForecasted,
    forecastGranularityHidden,
  };
}
