import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  formulaBuilderTabMain: {},

  forecastDestinationSection: {
    paddingBottom: 25,
  },
});
