import {store} from "@app/client-store";
import {useAppDispatch, useAppSelector} from "@app/hooks";
import {askForConfirmation} from "@app/ui-state/slice";
import RightSidebar from "@components/RightSidebar";
import RoundButton from "@components/RoundButton";
import RoundIcon from "@components/RoundIcon";
import SimpleTooltip from "@components/Tooltip/Simple";
import {removeRow} from "@shared/state/template-rows/slice";
import {removeCbTx} from "@state/cb-tx/slice";
import {selectDepartmentEntities} from "@state/departments/slice";
import {selectVersionLocked} from "@state/global/slice";
import {selectQboIntegrationVendors} from "@state/integrations/slice";
import {projKey} from "@state/utils";
import {useEffect, useState} from "react";

import {selectSelectedRow, selectors} from "../state/selectors";
import {updateTemplatesViewState} from "../state/slice";
import SidebarTabs from "./SidebarTabs";

export default function TemplatesSidebar() {
  const {column, rowId, departmentId, vendor} = useAppSelector(selectors.activeCell);
  const selectedRow = useAppSelector(selectSelectedRow);
  const dispatch = useAppDispatch();
  const isVersionLocked = useAppSelector(selectVersionLocked);
  const vendorsMapping = useAppSelector(selectQboIntegrationVendors);
  const departmentEntities = useAppSelector(selectDepartmentEntities);
  const [expanded, setExpanded] = useState(false);
  const [tab, setTab] = useState<string>("");

  useEffect(() => {
    setExpanded(false);
  }, [rowId]);

  const handleDelete = () => {
    if (rowId) {
      dispatch(
        askForConfirmation({
          preset: "delete",
          text: `Are you sure you want to delete the row <b style="white-space: nowrap">${selectedRow?.display_name}</b>?<br /><br />All of the transactions and forecasts belonging to this row will be deleted.`,
          onConfirm: () => {
            const state = store.getState();
            dispatch(
              updateTemplatesViewState({
                activeCell: {rowId: null, column: null},
                caller: "TemplatesSidebar->handleDelete",
              }),
            );
            const scenarioIds = state.scenarios.ids;
            const cbTxIdsToDelete: string[] = [];
            for (const scenarioId of scenarioIds) {
              cbTxIdsToDelete.push(...(state.cbTx.idsByRowId[projKey(rowId, scenarioId)] ?? []));
            }
            if (cbTxIdsToDelete) dispatch(removeCbTx(cbTxIdsToDelete));
            dispatch(removeRow(rowId));
            setTab("");
          },
        }),
      );
    }
  };

  const onClose = () => {
    dispatch(
      updateTemplatesViewState({
        activeCell: {rowId: null, column: null},
        caller: "TemplatesSidebar->onClose",
      }),
    );
    setExpanded(false);
  };

  const titleTags: React.ReactNode[] = [];
  let title = selectedRow?.display_name || "Untitled Row";
  if (departmentId) {
    const department = departmentEntities[departmentId];
    titleTags.push(
      <SimpleTooltip key="department" text={department?.display_name ?? ""} delay={0}>
        <RoundIcon mainColor="blue" icon="department" iconColor="tertiaryBlue" />
      </SimpleTooltip>,
    );
    title = department?.display_name ?? "Unknown Department";
  }

  if (vendor) {
    const vendorName = vendor === "no_vendor" ? "No Vendor" : vendorsMapping[vendor]?.displayName ?? "Unknown Vendor";
    titleTags.push(
      <SimpleTooltip key="vendor" text={vendorName}>
        <RoundIcon mainColor="blue" icon="vendor" iconColor="tertiaryBlue" />
      </SimpleTooltip>,
    );
    title = vendorName;
  }

  return !!selectedRow && !column ? (
    <RightSidebar
      onClose={onClose}
      title={title}
      titleTags={titleTags.length ? titleTags : undefined}
      expandable={tab === "data"}
      expandToFullWidth
      onExpandChange={setExpanded}
      topRightButtons={
        !isVersionLocked
          ? [
              <SimpleTooltip key="delete" text="Delete Row" placement="left">
                <RoundButton enableCssStates icon="trash" onClick={handleDelete} />
              </SimpleTooltip>,
            ]
          : []
      }
    >
      <SidebarTabs expanded={expanded} onTabChange={setTab} />
    </RightSidebar>
  ) : null;
}
