import colors from "@app/styles/colors";
import {getSvgColorStylesFromProps} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

import type {TagProps} from "./index";

export const sharedValues = {};

type TagStyleProps = Pick<TagProps, "color" | "hover">;

const colorMapping = {
  blue: {
    bg: "rgba(222, 236, 255, 0.9)",
    text: "rgba(49, 102, 151, 1)",
    hover: colors.tertiary.blue,
    active: colors.tertiary.lightBlue01,
  },
  red: {
    bg: "rgba(252, 191, 191, 0.4)",
    text: "#BD5858",
    hover: "rgba(252, 191, 191, 0.4)",
    active: "rgba(252, 191, 191, 0.4)",
  },
  green: {
    bg: "rgba(168, 228, 177, 0.4)",
    text: colors.other.darkGreen02,
    hover: "rgba(255, 223, 189, 0.5)",
    active: "rgba(255, 223, 189, 0.5)",
  },
  orange: {
    bg: "rgba(255, 223, 189, 0.5)",
    text: "rgba(224, 129, 27, 1)",
    hover: "rgba(255, 223, 189, 0.5)",
    active: "rgba(255, 223, 189, 0.5)",
  },
};

export default createUseStyles({
  tagMain: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 23,
    display: "inline-flex",
    alignItems: "center",
    backgroundColor: ({color}: TagStyleProps) => colorMapping[color].bg,
    color: ({color}: TagStyleProps) => colorMapping[color].text,
    transition: "all 60ms ease",
    borderRadius: 9999,
    padding: [4, 18],
    whiteSpace: "nowrap",

    "& > div:first-child": {
      marginRight: 5,
    },

    "& > div:last-child": {
      marginLeft: 7,
    },

    "&$small": {
      padding: [2, 12],
      fontSize: 11,
    },

    extend: getSvgColorStylesFromProps(
      "color",
      null,
      Object.fromEntries(Object.entries(colorMapping).map(([key, value]) => [key, value.text])),
    ),

    "&:not($active):hover": {
      backgroundColor: ({color, hover}: TagStyleProps) =>
        hover && colorMapping[color]?.hover ? colorMapping[color].hover : colorMapping[color]?.bg,
      color: ({color, hover}: TagStyleProps) => (hover ? colors.text.white : colorMapping[color].text),

      extend: ({color, hover}: TagStyleProps) =>
        hover
          ? {}
          : getSvgColorStylesFromProps(
              "color",
              null,
              Object.fromEntries(Object.entries(colorMapping).map(([key, value]) => [key, colors.text.white])),
            ),
    },
  },

  active: {
    backgroundColor: ({color, hover}: TagStyleProps) =>
      hover && colorMapping[color]?.active ? colorMapping[color].active : colorMapping[color]?.bg,
    color: ({color, hover}: TagStyleProps) => (hover ? colors.text.white : colorMapping[color].text),

    extend: getSvgColorStylesFromProps(
      "color",
      null,
      Object.fromEntries(Object.entries(colorMapping).map(([key, value]) => [key, colors.text.white])),
    ),
  },

  small: {},
  medium: {},
});
