import {createSelector} from "@reduxjs/toolkit";
import {time, timeEnd} from "@shared/lib/debug-provider";
import {getForecastTypeForRow} from "@shared/lib/row-utilities";
import {isCellDisabled} from "@shared/lib/templates-utilities";
import {typedObjectFromEntries} from "@shared/types/misc";
import {selectScenarioId, selectVersionLocked} from "@state/global/slice";
import dayjs from "dayjs";

import selectTemplateData from "./select-template-data";
import {selectSelectedTemplate, selectors} from "./selectors";

import type {DsError} from "@shared/types/alerts";
import type {Integration, SanityCheck, TemplateRow} from "@shared/types/db";
import type {RootState} from "@state/store";
import type {RowTypeForUI} from "@state/templates/selectors";

export type CellForDisplay = {
  dateKey: string;
  editable: boolean;
  firstMonthOfForecast: boolean;
  formattedValue: string;
  otherUserSelectedName: string | null;
  value: number | string | null;
  error?: DsError;
  firstErroredCell?: boolean;
  lastErroredCell?: boolean;
};

export type RowForDisplay<T extends TemplateRow = TemplateRow> = {
  isTotal: boolean;
  row: T;
  cells: CellForDisplay[];
  depth: number;
  collapsible: boolean;
  collapsed: boolean;
  otherUserSelectedIndexes: string;
  editableIndexes: string;
  hasChildRows: boolean;
  valuesAsString: string;
  departmentId: string | null;
  vendor: string | null;
  isEmptyHeader: boolean;
  empty: boolean;
  hidden: boolean;
  expanded: boolean;
  sanityCheck: SanityCheck | null;
  integration: Integration | null;
  type: RowTypeForUI;
};

const stateIndexesToKeepTrackOf = ["editable", "otherUserSelectedName"] as const;
type StateIndexesToKeepTrackOf = (typeof stateIndexesToKeepTrackOf)[number];

const selectTemplateUiData = createSelector(
  (state: RootState) => state.datasources,
  selectTemplateData,
  selectSelectedTemplate,
  selectScenarioId,
  selectors.otherUsersSelectedCells,
  selectVersionLocked,
  (datasourcesState, templateData, template, scenarioId, otherUsersSelectedCells, isVersionLocked) => {
    time("Rows", "Generate rowsForDisplay");
    if (!template) return {rowsForDisplay: [], stringifiedRowsForDisplay: []};

    const firstMonthOfForecast = dayjs(template.options.lastMonthOfActuals, "YYYY-MM")
      .add(1, "month")
      .format("YYYY-MM");

    const rowsForDisplay: RowForDisplay[] = [];
    for (const {
      row,
      data,
      collapsed,
      isTotal,
      depth: indentation,
      hasChildRows,
      valuesAsString,
      departmentId,
      vendor,
      isEmptyHeader,
      empty,
      hidden,
      expanded,
      sanityCheck,
      type,
      sanityCheckIntegration: integration,
    } of templateData) {
      const rowCellsForDisplay: CellForDisplay[] = [];
      // Track in-row cell states
      const indexesInRow: Record<StateIndexesToKeepTrackOf, number[]> = typedObjectFromEntries(
        stateIndexesToKeepTrackOf.map((key) => [key, [] as number[]]),
      );

      const nameCellOtherUserSelected =
        otherUsersSelectedCells.length &&
        otherUsersSelectedCells[0].rowId === row.id &&
        otherUsersSelectedCells[0].dateKey === "name"
          ? otherUsersSelectedCells[0]
          : null;

      const nameCell: CellForDisplay = {
        dateKey: data[0]?.formatted,
        editable: !isVersionLocked && row.type === "generic" && !departmentId && !vendor,
        firstMonthOfForecast: false,
        otherUserSelectedName: nameCellOtherUserSelected?.username || null,
        formattedValue: data[0]?.formatted,
        value: data[0]?.value, // TODO: handle hiring plan rows
        error: data[0]?.error,
      };

      rowCellsForDisplay.push(nameCell);
      for (const key of stateIndexesToKeepTrackOf) {
        if (nameCell[key]) indexesInRow[key].push(0);
      }
      for (const [i, {value, formatted, dateKey, error}] of data.entries()) {
        if (i === 0) continue;
        const prevCell: (typeof data)[number] | null = data[i - 1] ?? null;
        const nextCell: (typeof data)[number] | null = data[i + 1] ?? null;

        const rowForecastType = getForecastTypeForRow(row, departmentId);

        // Resolve states for cell
        const editable =
          ((rowForecastType === "row" && !departmentId && !vendor) ||
            (rowForecastType === "department" && departmentId && !vendor) ||
            (rowForecastType === "vendor" && !!vendor)) &&
          !(!departmentId && row.type === "account" && row.options.forecastType === "department") &&
          !isVersionLocked &&
          !isTotal &&
          !isCellDisabled(row, dateKey, scenarioId!, template, datasourcesState) &&
          !collapsed;
        const otherUserSelected =
          !isTotal &&
          otherUsersSelectedCells.length &&
          otherUsersSelectedCells[0].rowId === row.id &&
          otherUsersSelectedCells[0].dateKey === dateKey
            ? otherUsersSelectedCells[0]
            : null;

        const newCell: CellForDisplay = {
          dateKey,
          editable,
          firstMonthOfForecast: template.type !== "hiring_plan" && firstMonthOfForecast === dateKey,
          formattedValue: formatted,
          otherUserSelectedName: otherUserSelected?.username || null,
          value,
          error,
          firstErroredCell: error && (!prevCell || !prevCell.error || prevCell.error.id !== error.id),
          lastErroredCell: error && (!nextCell || !nextCell.error || nextCell.error.id !== error.id),
        };

        rowCellsForDisplay.push(newCell);

        for (const key of stateIndexesToKeepTrackOf) {
          if (nameCell[key]) indexesInRow[key].push(i);
        }
      }

      rowsForDisplay.push({
        isTotal,
        row,
        cells: rowCellsForDisplay,
        depth: indentation,
        collapsible: hasChildRows,
        collapsed,
        departmentId,
        vendor,
        hasChildRows,
        editableIndexes: indexesInRow.editable.join(","),
        otherUserSelectedIndexes: indexesInRow.otherUserSelectedName.join(","),
        valuesAsString,
        isEmptyHeader,
        empty,
        hidden,
        expanded,
        sanityCheck: sanityCheck ?? null,
        type,
        integration: integration ?? null,
      });
    }

    timeEnd("Rows", "Generate rowsForDisplay");
    return {rowsForDisplay, stringifiedRowsForDisplay: ""};
  },
);

export default selectTemplateUiData;
