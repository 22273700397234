import {addHeadsUpNotification, setNotificationContent} from "@app/ui-state/slice";
import {integrationMappings} from "@shared/lib/integration-utilities";

import type {UiState} from "@app/ui-state/slice";
import type {ClientWebsocketListener} from "@app/websockets";

import {loadGlobalData} from "@/lib/data-loading";

const handler: ClientWebsocketListener<"integration-sync"> =
  (store) =>
  ({integration, status, error, monthBeingPulled}) => {
    const notifOptions: UiState["notification"] = {
      cancelButton: false,
      confirmButton: false,
      preset: "integrationSync",
      closeButton: false,
      title: "",
      text: "",
      progress: null,
      actionButtonText: undefined,
      width: 550,
      onConfirm: () => {
        window.location.reload();
      },
    };

    if (status === "authenticating") {
      notifOptions.title = "Authenticating";
      notifOptions.text = `Authenticating with ${integrationMappings[integration.provider].name}...`;
      notifOptions.progress = {currentStep: 1, totalSteps: 5};
    } else if (status === "syncing") {
      notifOptions.title = "Syncing";
      notifOptions.text = `Pulling transactions from<br />${integrationMappings[integration.provider].shortName}`;
      if (monthBeingPulled) {
        notifOptions.text += `: <strong>${monthBeingPulled}</strong>...`;
      } else {
        notifOptions.text += "...";
      }
      notifOptions.mainColor = "blue";
      notifOptions.progress = {currentStep: 2, totalSteps: 5};
    } else if (status === "overwriting") {
      notifOptions.title = "Overwriting";
      notifOptions.text = `Overwriting model Actuals with latest transactions.`;
      notifOptions.backgroundImage = "/assets/images/ui/modals/notifs/blue-anxious.svg";
      notifOptions.progress = {currentStep: 3, totalSteps: 5};
    } else if (status === "recalculating") {
      notifOptions.title = "Recalculating";
      notifOptions.text = `Recalculating your forecast and saving the values.`;
      notifOptions.backgroundImage = "/assets/images/ui/modals/notifs/blue-satisfied.svg";
      notifOptions.progress = {currentStep: 4, totalSteps: 5};
    } else if (status === "done") {
      notifOptions.title = "Reloading";
      notifOptions.text = `Reloading the app with the latest changes. Almost there!`;
      notifOptions.backgroundImage = "/assets/images/ui/modals/notifs/blue-happy.svg";
      notifOptions.progress = {currentStep: 5, totalSteps: 5};

      // Trigger global data reload
      loadGlobalData({store, firstLoad: false, markInitialLoadAsNotDone: false})?.then(() => {
        store.dispatch(setNotificationContent(null));
        store.dispatch<any>(
          addHeadsUpNotification({
            title: "Sync Complete!",
            text: `The model has been updated with the latest changes. Keep bringing in the cash!`,
            preset: "success",
          }),
        );
      });
    } else if (status === "error") {
      // if (error === "AUTH_ERROR") {
      //   notifOptions.title = `Authentication Failed`;
      //   notifOptions.backgroundImage = "/assets/images/ui/modals/notifs/orange-default.svg";
      //   notifOptions.mainColor = "orange";
      //   notifOptions.text = `Authentication with ${
      //     integrationMappings[integration.provider].name
      //   } failed. Please reconnect.`;
      //   notifOptions.actionButtonIcon = integration.provider === "quickbooks-online" ? "qbLogo" : "snowflake";
      //   notifOptions.actionButtonIconSize = 16;
      //   notifOptions.actionButtonText = "Reconnect";
      //   notifOptions.confirmButton = true;
      //   notifOptions.closeButton = true;
      //   notifOptions.cancelButton = true;
      //   notifOptions.onConfirm = () => {
      //     window.location.href = `/api/connect/intuit/${integration.id}`;
      //   };
      // } else {
      //   notifOptions.title = "Error";
      //   notifOptions.text = `An error occurred while pulling transactions from ${
      //     integrationMappings[integration.provider].name
      //   }.`;
      //   notifOptions.closeButton = true;
      //   notifOptions.actionButtonText = "Close";
      //   notifOptions.cancelButton = false;
      //   notifOptions.mainColor = "red";
      //   notifOptions.backgroundImage = "/assets/images/ui/modals/notifs/red-default.svg";
      // }
      switch (error) {
        case "AUTH_ERROR":
          notifOptions.title = `Authentication Failed`;
          notifOptions.backgroundImage = "/assets/images/ui/modals/notifs/orange-default.svg";
          notifOptions.mainColor = "orange";
          notifOptions.text = `Authentication with ${
            integrationMappings[integration.provider].name
          } failed. Please reconnect.`;
          notifOptions.actionButtonIcon = integration.provider === "quickbooks-online" ? "qbLogo" : "snowflake";
          notifOptions.actionButtonIconSize = 16;
          notifOptions.actionButtonText = "Reconnect";
          notifOptions.confirmButton = true;
          notifOptions.closeButton = true;
          notifOptions.cancelButton = true;
          notifOptions.onConfirm = () => {
            window.location.href = `/api/connect/intuit/${integration.id}`;
          };
          break;
        case "OAUTH_ERROR":
          notifOptions.title = "Authentication Failed";
          notifOptions.text = `There was an error during the Quickbooks Online authorization process. Please try again later.`;
          notifOptions.closeButton = true;
          notifOptions.cancelButton = false;
          notifOptions.mainColor = "red";
          notifOptions.backgroundImage = "/assets/images/ui/modals/notifs/red-default.svg";
          break;
        case "CREDENTIALS_INVALID":
          notifOptions.title = "Error";
          notifOptions.text = `The existing credentials for ${
            integrationMappings[integration.provider].name
          } are invalid. Please reconnect.`;
          notifOptions.actionButtonText = "Reconnect";
          notifOptions.confirmButton = true;
          notifOptions.closeButton = true;
          notifOptions.cancelButton = true;
          // notifOptions.mainColor = "orange";
          notifOptions.onConfirm = () => {
            window.location.href = `/api/connect/intuit/${integration.id}`;
          };
          break;
        default:
          notifOptions.title = "Error";
          notifOptions.text = `An internal server error occurred while syncing data with ${
            integrationMappings[integration.provider].name
          }. Please try again later.`;
          notifOptions.closeButton = true;
          notifOptions.actionButtonText = "Close";
          notifOptions.confirmButton = true;
          notifOptions.cancelButton = false;
          notifOptions.mainColor = "red";
          notifOptions.height = 225;
          notifOptions.backgroundImage = "/assets/images/ui/modals/notifs/red-default.svg";
          notifOptions.onConfirm = () => {};
          break;
      }
    }
    store.dispatch(setNotificationContent(notifOptions));
  };

export default handler;
