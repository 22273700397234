import {useAppDispatch, useAppSelector} from "@app/hooks";
import Button from "@components/Button";
import Dropdown from "@components/Dropdown";
import Popover from "@components/Popover";
import Tag from "@components/Tag";
import TemplateOptions from "@features/templates/TemplateOptions";
import {selectScenarioId, setScenarioId} from "@shared/state/global/slice";
import {selectAllScenarios} from "@shared/state/scenarios/slice";
import clsx from "clsx";
import {useState} from "react";

import useStyles, {sharedValues} from "./styles.jss";

import type {DropdownItem} from "@components/Dropdown";
import type {Scenario} from "@shared/types/db";

export interface ScenarioSelectorProps {
  width?: number;
  showDisplayOptions?: boolean;
}

export default function ScenarioSelector({
  width = sharedValues.scenarioDropdownDefaultWidth,
  showDisplayOptions = false,
}: ScenarioSelectorProps) {
  const styles = useStyles({showDisplayOptions});
  const scenarioId = useAppSelector(selectScenarioId);
  const scenarios = useAppSelector(selectAllScenarios);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const selectedScenario = scenarios.find((scenario) => scenario.id === scenarioId) as Scenario;

  const dropdownItems: DropdownItem[] = scenarios.map((scenario) => ({
    key: scenario.id,
    value: scenario.name,
    selected: scenarioId === scenario.id,
  }));

  const handleScenarioChange = (item: DropdownItem) => {
    dispatch(setScenarioId(item.key));
  };

  const classes = clsx(styles.scenarioDropdownWrapper);

  return (
    <div className={classes}>
      <Dropdown
        items={dropdownItems}
        onSelect={handleScenarioChange}
        width={175}
        align="center"
        onClose={() => setIsOpen(false)}
      >
        <Tag
          active={isOpen}
          color="blue"
          text={selectedScenario.name}
          iconRight="dropdown"
          hover
          onClick={() => setIsOpen(!isOpen)}
        />
      </Dropdown>
      {showDisplayOptions ? (
        <Popover arrow disableSelection closeOnClick={false} content={<TemplateOptions />} width={400}>
          <Button iconRight="calendar" className={styles.iconButtonRight} color="lightOrange" minimal outline />
        </Popover>
      ) : null}
    </div>
  );
}
