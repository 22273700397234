import integrationSyncListener from "@features/settings/Integrations/websocket-handlers";
import createVersionListener from "@features/shared-views/CreateVersion/websocket-handlers";
import {setTemplatesViewState} from "@features/templates/state/slice";

import {setNotificationContent} from "./ui-state/slice";
import {socket, typeWebsocketListeners} from "./websockets";

export default typeWebsocketListeners({
  "out-of-sync": (store) => () => {
    socket.off("sync"); // Disable syncing incoming events from API
    store.dispatch(
      setNotificationContent({
        preset: "outOfSync",
        cancelButton: false,
        closeButton: false,
        text: `Changes have been made to the data since you were last active. The page needs to be refreshed.`,
        onConfirm: () => {
          window.location.reload();
        },
      }),
    );
  },
  "create-scenario-progress":
    (store) =>
    ({status, details}) => {
      // if (!store.getState().templatesView.createScenarioOpen) return;
      const displayEntity = details?.entity === "templateRows" ? "rows" : details?.entity;
      let text = "";
      if (status === "done") {
        text = "done";
      } else {
        text = `Copying ${displayEntity}`;
        if (details?.entity === "transactions") {
          text += ` (${details?.done} / ${details.total})`;
        }
        text += "...";
      }
      store.dispatch(
        setTemplatesViewState({
          key: "createScenarioProgress",
          value: text,
        }),
      );
    },
  "create-version-progress": createVersionListener,
  "integration-sync": integrationSyncListener,

  reconnect: (store) => () => socket.emit("validate-last-message-id", store.getState().global.lastSync?.messageId),
} as const);
