import {clientDispatchInBothThreads} from "@app/client-store";
import {useAppDispatch, useAppSelector} from "@app/hooks";
import {updateUiState} from "@app/ui-state/slice";
import hiringPlanTopNavItems from "@features/hiring-plan/top-nav-items";
import templatesPlanTopNavItems from "@features/templates/top-nav-items";
import {selectAllDepartments} from "@state/departments/slice";
import {
  selectDepartmentId,
  selectVersionId,
  selectVersionUnlocked,
  setGlobalStatePropertyValue,
} from "@state/global/slice";
import {selectAllTemplates} from "@state/templates/slice";
import {selectAllVersions} from "@state/versions/slice";
import dayjs from "dayjs";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

import {getDepartmentDropdownItems, handleDropdownChange} from "./breadcrumbs-utilities";

import type {DropdownItem} from "@components/Dropdown";
import type {Location, NavigateFunction, URLSearchParamsInit} from "react-router-dom";
import type {BreadcrumbItem} from "./BreadcrumbItem";

export type BreadcrumbsProvider = ({
  location,
  searchParams,
  setSearchParams,
}: {
  searchParams: URLSearchParams;
  setSearchParams: (nextInit: URLSearchParamsInit) => void;
  location: Location;
  navigate: NavigateFunction;
  templates: ReturnType<typeof selectAllTemplates>;
}) => BreadcrumbItem[];

const breadcrumbsProviderMapping: Record<string, BreadcrumbsProvider> = {
  "hiring-plan": hiringPlanTopNavItems.useBreadcrumbItems,
  "financial-statements": templatesPlanTopNavItems.useFinancialStatementsBcItems,
  templates: templatesPlanTopNavItems.useTemplatesBcItems,
  worksheets: templatesPlanTopNavItems.useTemplatesBcItems,
};

export function useBcItems() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const templates = useAppSelector(selectAllTemplates);
  const feature = location.pathname.split("/")[1].split("/")[0];
  const useFeatureSpecificBcItems =
    !feature || !breadcrumbsProviderMapping[feature] ? () => [] : breadcrumbsProviderMapping[feature];

  const featureSpecificBcItems = useFeatureSpecificBcItems({
    location,
    searchParams,
    setSearchParams,
    navigate,
    templates,
  });
  const versionsBcItem = useVersionsBcItem();
  const departmentBcItem = useDepartmentBcItem();

  const breadcrumbItems = [...featureSpecificBcItems];

  breadcrumbItems.push(versionsBcItem);
  if (featureSpecificBcItems.length) breadcrumbItems.push(departmentBcItem);

  return breadcrumbItems;
}

export function useVersionsBcItem(): BreadcrumbItem {
  const versions = useAppSelector(selectAllVersions);
  const selectedVersionId = useAppSelector(selectVersionId);
  const versionUnlocked = useAppSelector(selectVersionUnlocked);
  const dispatch = useAppDispatch();

  const selectedVersion = versions.find((version) => version.id === selectedVersionId);

  const longestVersionName = versions?.length
    ? versions.reduce((a, b) => (a.display_name.toString().length > b.display_name.toString().length ? a : b))
        .display_name
    : "Current";

  const saveVersionItemKey = "::create";

  // TODO: make sure it works for most sizes
  const width = 30 + 115 + longestVersionName.toString().length * 8;

  const handleVersionChange = (item: DropdownItem) => {
    if (item.key === saveVersionItemKey) {
      dispatch(updateUiState({saveVersionModalOpen: true}));
    } else {
      clientDispatchInBothThreads(setGlobalStatePropertyValue({key: "version", value: item.key}));
      clientDispatchInBothThreads(setGlobalStatePropertyValue({key: "versionUnlocked", value: false}));
    }
  };

  const dropdownItems: DropdownItem[] = [
    {key: null, value: "Current", selected: !selectedVersionId},
    ...versions
      .toSorted((a, b) => b.created_at.localeCompare(a.created_at))
      .map((version) => ({
        key: version.id,
        value: version.display_name,
        selected: version.id === selectedVersionId,
        disabled: version.status !== "ready",
        rightText:
          version.status === "initializing"
            ? "Initializing..."
            : version.status === "failed"
            ? "Saving failed"
            : dayjs(version.created_at).format("MM/DD/YYYY h:mm A"),
      })),
    {
      key: saveVersionItemKey,
      value: "Save Version...",
      selected: false,
      disabled: !!selectedVersionId,
      iconLeft: "plus",
      borderTop: true,
    },
  ];

  const handleLockClick = () => {
    clientDispatchInBothThreads(setGlobalStatePropertyValue({key: "versionUnlocked", value: !versionUnlocked}));
  };

  return {
    text: `Version: ${selectedVersion?.display_name ?? "Current"}`,
    onDropdownChange: handleVersionChange,
    dropdownWidth: width,
    dropdownItems,
    rightButtonIcon:
      selectedVersion && !versionUnlocked ? "locked" : selectedVersion && versionUnlocked ? "unlocked" : null,
    rightButtonOnClick: handleLockClick,
  };
}

export function useDepartmentBcItem(): BreadcrumbItem {
  const departments = useAppSelector(selectAllDepartments);
  const selectedDepartmentId = useAppSelector(selectDepartmentId);

  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const allDepartmentsText = "All Departments";
  const longestDepartmentName = departments.length
    ? departments.reduce((a, b) => (a.display_name.toString().length > b.display_name.toString().length ? a : b))
        .display_name
    : allDepartmentsText;

  // TODO: make sure it works for most sizes
  const width = 30 + longestDepartmentName.toString().length * 8;
  const selectedDepartment = departments.find((department) => department.id === selectedDepartmentId);

  const dropdownItems = getDepartmentDropdownItems(departments, selectedDepartment?.id ?? null, allDepartmentsText);

  return {
    text: selectedDepartment?.display_name ?? allDepartmentsText,
    onDropdownChange: handleDropdownChange(searchParams, setSearchParams, dispatch),
    dropdownWidth: width,
    dropdownItems,
  };
}
