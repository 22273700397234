import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import id from "@shared/lib/id";
import {generateClientSelectors} from "@shared/lib/misc";
import {setAutoFreeze} from "immer";

import globalApi from "./api";

import type {PayloadAction, SerializedError} from "@reduxjs/toolkit";
import type {DependencyCache} from "@shared/data-functions/cache/dependency-cache";
import type {RootState} from "@state/store";

setAutoFreeze(false);

export const api = {
  fetchLastMessageId: createAsyncThunk("global/lastMessageId", globalApi.fetchLastMessageId),
  // fetchMonthlyCache: createAsyncThunk("transactions/fetchMonthlyCache", transactionsAPI.fetchMonthlyCache),
};

export type ApiRequest = {
  requestId: string;
  description: string;
  startedAt: number;
  completedAt: number | null;
  error: SerializedError | null;
};

export interface GlobalState {
  requests: ApiRequest[];
  lastSave: string | null;
  initialLoadDone: boolean;
  fullCacheLoadDone: boolean;
  scenarioId: string | null;
  lastSync: {name: string; time: string; messageId: string} | null;
  lastFullRefresh: number | null;
  version: null | string;
  versionUnlocked: boolean;
  selectedDepartmentId: string | null;
  dependencyCache: Record<string, DependencyCache>;
  algoCache: Record<string, Record<string, number>>;
  departmentsExpandedRowIds: string[];
  expandedVendors: Record<string, string[]>;
  statusText: string | null;
  instanceId: string;
  debugModeEnabled: boolean;
}

const initialState: GlobalState = {
  requests: [],
  lastSave: null,
  initialLoadDone: false,
  fullCacheLoadDone: false,
  scenarioId: null,
  lastSync: null,
  lastFullRefresh: null,
  version: null,
  versionUnlocked: false,
  selectedDepartmentId: null,
  dependencyCache: {},
  algoCache: {},
  departmentsExpandedRowIds: [],
  expandedVendors: {},
  statusText: null,
  instanceId: id(),
  debugModeEnabled: false,
};

export const getSlice = () => {
  return createSlice({
    name: "global",
    initialState,
    reducers: {
      updateGlobalState: (state, action: PayloadAction<Partial<GlobalState>>) => ({
        ...state,
        ...action.payload,
      }),
      setGlobalStatePropertyValue: <K extends keyof GlobalState>(
        state: GlobalState,
        action: PayloadAction<{key: K; value: GlobalState[K]}>,
      ) => {
        state[action.payload.key] = action.payload.value;
      },
      requestStarted: (state, action: PayloadAction<{requestId: string; description: string}>) => {
        const {requestId, description} = action.payload;
        const newRequest: ApiRequest = {
          requestId,
          description,
          startedAt: Date.now(),
          completedAt: null,
          error: null,
        };

        // console.log(`Started request:`, newRequest);
        return {...state, requests: [...state.requests, newRequest]};
      },
      requestCompleted: (state, action: PayloadAction<string>) => {
        const matchingRequest = state.requests.find((item) => item.requestId === action.payload);
        if (matchingRequest) {
          matchingRequest.completedAt = Date.now();
          // console.log(`Completed request (${matchingRequest.completedAt - matchingRequest.startedAt}ms):`, {
          //   ...matchingRequest,
          // });
        } else {
          // console.error("Tried to mark unknown request as completed:", action.payload);
        }
      },
      requestFailed: (state, action: PayloadAction<{requestId: string; error: SerializedError}>) => {
        const matchingRequest = state.requests.find((item) => item.requestId === action.payload.requestId);
        if (matchingRequest) {
          matchingRequest.completedAt = Date.now();
          matchingRequest.error = action.payload.error;
          // console.error(`Request failed (${matchingRequest.completedAt - matchingRequest.startedAt}ms):`, {
          //   ...matchingRequest,
          // });
        } else {
          // console.error("Tried to mark unknown request as failed:", action.payload);
        }
      },
      setInitialLoadDone: (state, action: PayloadAction<boolean>) => {
        state.initialLoadDone = action.payload;
      },

      setScenarioId: (state, action: PayloadAction<string | null>) => {
        state.scenarioId = action.payload;
      },
      setLastSync: (state, action: PayloadAction<GlobalState["lastSync"]>) => {
        state.lastSync = action.payload;
      },
      toggleDepartmentsExpanded: (state, action: PayloadAction<{rowId: string; forceState?: boolean}>) => {
        const rowIndex = state.departmentsExpandedRowIds.indexOf(action.payload.rowId);
        if (rowIndex > -1 && action.payload.forceState !== true) {
          state.departmentsExpandedRowIds.splice(rowIndex, 1);
        } else if (action.payload.forceState !== false && rowIndex === -1) {
          state.departmentsExpandedRowIds.push(action.payload.rowId);
        }
      },
      toggleVendorsExpanded: (
        state,
        action: PayloadAction<{rowId: string; departmentId: string | string[]; forceState?: boolean}>,
      ) => {
        state.expandedVendors[action.payload.rowId] ||= [];
        for (const departmentId of Array.isArray(action.payload.departmentId)
          ? action.payload.departmentId
          : [action.payload.departmentId]) {
          const index = state.expandedVendors[action.payload.rowId].indexOf(departmentId);
          if (index > -1 && action.payload.forceState !== true) {
            state.expandedVendors[action.payload.rowId].splice(index, 1);
          } else if (action.payload.forceState !== false && index === -1) {
            state.expandedVendors[action.payload.rowId].push(departmentId);
          }
        }
      },
    },
    extraReducers: (builder) => {
      builder.addCase(api.fetchLastMessageId.fulfilled, (state, action) => {
        if (!action.payload) state.lastSync = null;
      });
    },
  });
};

const _slice = getSlice();

export const {
  requestStarted,
  requestCompleted,
  requestFailed,
  setInitialLoadDone,
  setScenarioId,
  setLastSync,
  setGlobalStatePropertyValue,
  updateGlobalState,
  toggleDepartmentsExpanded,
  toggleVendorsExpanded,
} = _slice.actions;

export const selectors = generateClientSelectors("global", _slice.getInitialState());

export const selectGlobal = (state: RootState) => state.global;
export const selectScenarioId = createSelector(selectGlobal, (globalState) => globalState.scenarioId);
export const selectAllRequests = (state: RootState) => state.global.requests;
export const selectVersionId = (state: RootState) => state.global.version;
export const selectDepartmentId = (state: RootState) => state.global.selectedDepartmentId;
export const selectVersionUnlocked = (state: RootState) => state.global.versionUnlocked;
export const selectVersionLocked = (state: RootState) => !!state.global.version && !state.global.versionUnlocked;
export const selectSaving = (state: RootState) => state.global.requests.some((item) => !item.completedAt);
export const selectLastSync = (state: RootState) => state.global.lastSync;
export const selectDependencyCache = (state: RootState) => state.global.dependencyCache;
export const selectLastRequestCompletedAt = (state: RootState) =>
  state.global.requests.sort((a, b) => ((a.completedAt || 0) > (b.completedAt || 0) ? 1 : -1)).at(-1)?.completedAt;

export default _slice.reducer;
