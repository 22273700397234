import api from "@shared/lib/fetch";

import type {Scenario} from "@shared/types/db";
import type {ApiEndpoints} from "@shared/state/store";

const endpoints = {
  fetchAll(_args, thunkAPI) {
    return api.get<Scenario[]>(thunkAPI, "scenarios");
  },
  create({scenario, basedOn}: {basedOn?: string | null; scenario: Scenario}, thunkAPI) {
    const query: Record<string, any> = {scenario};
    if (basedOn) query.basedOn = basedOn;
    return api.put<void>(thunkAPI, "scenarios/create", query);
  },
} satisfies ApiEndpoints;

export default endpoints;
