import {resumeListener, socket} from "@app/websockets";
import {markPendingSyncAsDone, syncIntegration} from "@state/integrations/slice";
import {changeLastMonthOfActuals, selectAllTemplates} from "@state/templates/slice";
import dayjs from "dayjs";

import type {ClientThunkAction} from "@app/client-store";

export const handleIntegrationSync =
  ({
    forceInitial,
    syncPeriod,
    integrationId,
  }: {
    forceInitial?: boolean;
    syncPeriod?: {
      from: string;
      to: string;
    };
    integrationId: string;
  }): ClientThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const integration = state.integrations.entities[integrationId];
    if (!integration) return;
    const syncParams: Parameters<typeof syncIntegration>[0] = {
      id: integrationId,
      initial: forceInitial,
      type: integration.provider === "quickbooks-online" ? "accounting" : "datawarehouse",
    };
    if (syncPeriod) {
      syncParams.from = dayjs(syncPeriod.from).format("YYYY-MM-DD");
      syncParams.to = dayjs(syncPeriod.to).format("YYYY-MM-DD");
    }
    dispatch(syncIntegration(syncParams));
    socket.once("integration-sync", ({integration: updatedIntegration, status}) => {
      if (status === "complete") {
        dispatch(markPendingSyncAsDone(updatedIntegration));
        window.location.reload();
      } else if (status === "error") {
        //setIsErrored(true);
      }
      resumeListener("out-of-sync");
      dispatch(markPendingSyncAsDone(integration));
    });
  };

export const handleLmoaChangeForIntegration =
  (integrationId: string, newLMOA: string, sync: boolean): ClientThunkAction =>
  (dispatch, getState) => {
    const state = getState();
    const templates = selectAllTemplates(state);
    dispatch(
      changeLastMonthOfActuals({
        templateIds: templates.filter(({type}) => type === "financial_statement").map(({id}) => id),
        newLMOA,
        integrationIdToSyncAfter: sync ? integrationId : undefined,
      }),
    );
  };
