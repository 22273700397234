import api from "@shared/lib/fetch";

import type {EntityId} from "@reduxjs/toolkit";
import type {ApiEndpoints} from "@shared/state/store";
import type {Employee, FinancialComponent, Team} from "@shared/types/hiring-plan";

const endpoints = {
  fetchHiringPlanEntities(_arg, thunkAPI) {
    return api.get<{
      teams: Team[];
      employees: Employee[];
      financialComponents: FinancialComponent[];
    }>(thunkAPI, "hiring-plan");
  },
  upsertEmployee(employee: Employee, thunkAPI) {
    return api.put<Employee>(thunkAPI, `hiring-plan/employees/${employee.id}`, employee);
  },
  removeEmployees(employeeIds: EntityId[], thunkAPI) {
    return api.del<Employee[]>(thunkAPI, `hiring-plan/employees`, employeeIds);
  },
} satisfies ApiEndpoints;

export default endpoints;
