import SVGIcon from "@components/SVGIcon";
import clsx from "clsx";
import {Link, useLocation} from "react-router-dom";

import useStyles from "./LeftNav.jss";

import type {LeftNavMenuItem} from "./LeftNav";

export default function LeftNavItem({item, parentItem}: {item: LeftNavMenuItem; parentItem?: LeftNavMenuItem}) {
  const styles = useStyles();
  const location = useLocation();

  const locationFullPath = location.pathname + location.search;
  const itemFullPath = parentItem
    ? `/${parentItem.path}${item.path[0] !== "?" ? "/" : ""}${item.path}`
    : `/${item.path}`;

  let active: boolean = false;
  if (item.activeStateResolver) {
    active = item.activeStateResolver(locationFullPath);
  } else {
    active = locationFullPath === itemFullPath;
  }

  const classes = clsx({
    [styles.leftNavItemWithChildren]: item.children?.length,
    [styles.leftNavItem]: !item.children?.length,
    [styles.secondLevel]: !!parentItem,
    [styles.activeItem]: active,
  });

  return (<>
    <Link to={itemFullPath}>
      <li className={classes}>
        <div className={styles.iconAndText}>
          {!item.icon ? (
            <SVGIcon name="bullet" />
          ) : (
            <div className={styles.icon}>
              <SVGIcon name={item.icon} />
            </div>
          )}
          <span>{item.text}</span>
        </div>
      </li>
    </Link>
    {item.children?.length
      ? item.children.map((topLevelItem) => (
          // eslint-disable-next-line react/jsx-indent
          (<LeftNavItem item={topLevelItem} key={topLevelItem.text} parentItem={item} />)
        ))
      : null}
  </>);
}
