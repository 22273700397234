import {useAppDispatch, useAppSelector} from "@app/hooks";
import RDPDatePicker from "@components/Datepicker/RDP"; // Update the import path
import TextInput from "@components/TextInput"; // Update the import path
import {addDeltaMonthsToDateKey, getFormattedFullDate} from "@shared/lib/date-utilities";
import {changeLastMonthOfActuals, selectAllTemplates, upsertTemplate} from "@state/templates/slice";
import React from "react";

import useStyles from "./styles.jss";

import type {TextInputProps} from "@components/TextInput";
import type {Template, TemplateOptions} from "@shared/types/db";

export interface StatsProps {}

const TemplateSettings: React.FC<StatsProps> = () => {
  const styles = useStyles();
  const templates = useAppSelector(selectAllTemplates);
  const dispatch = useAppDispatch();

  const handleTextInputChange =
    (template: Template): TextInputProps["onChange"] =>
    (event) => {
      dispatch(upsertTemplate({...template, display_name: event.target.value}));
    };

  const handleDatePickerChange = <
    K extends keyof TemplateOptions & ("start" | "end" | "visibleStart" | "visibleEnd" | "lastMonthOfActuals"),
  >(
    template: Template,
    key: K,
    value: TemplateOptions[K],
  ) => {
    const templatesImpacted =
      template.type === "financial_statement"
        ? templates.filter((template) => template.type === "financial_statement")
        : [template];
    let formattedValue = typeof value === "string" ? value.slice(0, 7) : value;
    if (key === "lastMonthOfActuals" && typeof formattedValue === "string") {
      dispatch(
        changeLastMonthOfActuals({
          templateIds: templatesImpacted.map(({id}) => id),
          newLMOA: formattedValue,
        }),
      );
    } else {
      for (const template of templatesImpacted) {
        const finalValue =
          template.name === "balance_sheet" && key === "start"
            ? addDeltaMonthsToDateKey(formattedValue, -1)
            : formattedValue;
        dispatch(upsertTemplate({...template, options: {...template.options, [key]: finalValue}}));
      }
    }
  };

  return (
    <div className={styles.templateSettings}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>Display Name</th>
            <th className={styles.th}>Start</th>
            <th className={styles.th}>End</th>
            <th className={styles.th}>Last Month of Actuals</th>
            <th className={styles.th}>Visible Start</th>
            <th className={styles.th}>Visible End</th>
          </tr>
        </thead>
        <tbody>
          {templates.map((template) => (
            <tr key={template.id}>
              <td className={styles.td}>
                <TextInput
                  value={template.display_name}
                  onChange={handleTextInputChange(template)}
                  changeTrigger="blur"
                  onChangeDelay={750}
                />
              </td>
              <td className={styles.td}>
                <RDPDatePicker
                  disabled={["balance_sheet", "cash_flow_statement"].includes(template.name)}
                  inline
                  value={getFormattedFullDate(template.options.start, "start")}
                  onChange={(date) => handleDatePickerChange(template, "start", date || "")}
                />
              </td>
              <td className={styles.td}>
                <RDPDatePicker
                  disabled={["balance_sheet", "cash_flow_statement"].includes(template.name)}
                  inline
                  value={getFormattedFullDate(template.options.end, "end")}
                  onChange={(date) => handleDatePickerChange(template, "end", date || "")}
                />
              </td>
              <td className={styles.td}>
                <RDPDatePicker
                  disabled={["balance_sheet", "cash_flow_statement"].includes(template.name)}
                  inline
                  value={getFormattedFullDate(template.options.lastMonthOfActuals, "end")}
                  onChange={(date) => handleDatePickerChange(template, "lastMonthOfActuals", date || "")}
                  max={template.options.end}
                  min={template.options.start}
                />
              </td>
              <td className={styles.td}>
                <RDPDatePicker
                  disabled={["balance_sheet", "cash_flow_statement"].includes(template.name)}
                  inline
                  value={getFormattedFullDate(template.options.visibleStart, "start")}
                  onChange={(date) => handleDatePickerChange(template, "visibleStart", date || "")}
                  max={template.options.end}
                  min={template.options.start}
                />
              </td>
              <td className={styles.td}>
                <RDPDatePicker
                  disabled={["balance_sheet", "cash_flow_statement"].includes(template.name)}
                  inline
                  value={getFormattedFullDate(template.options.visibleEnd, "end")}
                  onChange={(date) => handleDatePickerChange(template, "visibleEnd", date || "")}
                  max={template.options.end}
                  min={template.options.start}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TemplateSettings;
