import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import scenariosAPI from "@shared/apis/scenarios";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getScenariosAdapter} from "@shared/state/entity-adapters";

import type {RootState} from "@state/store";

export const fetchScenarios = createAsyncThunk("scenarios/fetchAll", scenariosAPI.fetchAll);
export const createScenario = createAsyncThunk("scenarios/create", scenariosAPI.create);

export const getSlice = () => {
  const scenariosAdapter = getScenariosAdapter();
  return createSlice({
    name: "scenarios",
    initialState: scenariosAdapter.getInitialState(),
    reducers: {
      upsertScenarios: scenariosAdapter.upsertMany,
      upsertScenariosLocal: scenariosAdapter.upsertMany,
      removeScenariosLocal: scenariosAdapter.removeMany,
    },
    extraReducers: (builder) => {
      builder.addCase(fetchScenarios.fulfilled, scenariosAdapter.setAll);
    },
  });
};

const _slice = getSlice();
export const {upsertScenariosLocal, removeScenariosLocal} = _slice.actions;
export const {upsertScenarios} = createSyncedActionCreators(_slice.actions);

export const {
  selectById: selectScenarioById,
  selectIds: selectScenarioIds,
  selectEntities: selectScenarioEntities,
  selectAll: selectAllScenarios,
  selectTotal: selectTotalScenarios,
} = getScenariosAdapter().getSelectors((state: RootState) => state.scenarios);
