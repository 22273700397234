import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import {pauseListener, socket} from "@app/websockets";
import Button from "@components/Button/V2";
import Checkbox from "@components/Checkbox";
import Dropdown from "@components/Dropdown";
import FormElement from "@components/FormElement";
import Modal from "@components/Modal";
import Tabs from "@components/Tabs";
import TextInput from "@components/TextInput";
import {selectors} from "@features/templates/state/selectors";
import id from "@shared/lib/id";
import {createScenario, selectAllScenarios} from "@state/scenarios/slice";
import {useState} from "react";

import useStyles from "./styles.jss";

import type {DropdownItem} from "@components/Dropdown";

interface CreateNewScenarioProps {
  onClose: () => void;
}
const tabsList = [{name: "details", value: "Details"}];

export default function CreateNewScenario({onClose}: CreateNewScenarioProps) {
  const styles = useStyles();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [duplicatedScenario, setDuplicatedScenario] = useState("none");
  const [saving, setSaving] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const scenarios = useAppSelector(selectAllScenarios);
  const dispatch = useAppThunkDispatch();
  const progressText = useAppSelector(selectors.createScenarioProgress);

  const items = [
    {key: "none", value: "None"},
    ...scenarios.map((scenario) => ({key: scenario.id, value: scenario.name})),
  ];

  const handleSave = async () => {
    setSaving(true);
    console.log({
      basedOn: duplicatedScenario === "none" ? null : duplicatedScenario,
      scenario: {
        name,
        description,
        default: isDefault,
      },
    });
    pauseListener("out-of-sync");
    socket.on("create-scenario-progress", ({status}) => {
      if (status === "done") {
        window.location.reload();
      }
    });
    dispatch(
      createScenario({
        basedOn: duplicatedScenario === "none" ? null : duplicatedScenario,
        scenario: {
          id: id(),
          name,
          description,
          default: isDefault,
        },
      }),
    );
  };

  const handleDuplicateScenarioSelect = (item: DropdownItem) => {
    setDuplicatedScenario(item?.key ?? "none");
  };

  return (
    <Modal onClose={onClose} backgroundImage="/assets/images/ui/scenario-modal-bg.svg">
      <div className={styles.createScenarioMain}>
        <Tabs tabs={tabsList} selectedTab={tabsList[0]} onChange={() => null} />

        <div className={styles.firstRow}>
          <div>
            <FormElement label="Scenario Name">
              <TextInput
                placeholder="Enter Scenario name..."
                value={name}
                onChange={(evt) => setName(evt.target.value)}
                disabled={saving}
              />
            </FormElement>
          </div>
          <div>
            <FormElement label={"Copy Existing Scenario"}>
              <Dropdown
                items={items}
                onSelect={handleDuplicateScenarioSelect}
                renderAsChild
                selectedKey={duplicatedScenario}
                disabled={saving}
              />
            </FormElement>
          </div>
        </div>
        <div className={styles.secondRow}>
          <FormElement label="Description" className={styles.secondRowFormElement}>
            <TextInput
              multiline
              placeholder="Scenario description..."
              className={styles.inputWrapper}
              value={description}
              onChange={(evt) => setDescription(evt.target.value)}
              disabled={saving}
            />
          </FormElement>
        </div>
        <div className={styles.thirdRow}>
          <Checkbox
            text="Set as the Default Scenario"
            onClick={() => setIsDefault(!isDefault)}
            checked={isDefault}
            disabled={saving}
            color="orange"
          />
        </div>
        <div className={styles.buttonsRow}>
          <Button color="orange" text="Create" onClick={handleSave} disabled={saving || !name} />
          <Button text="Cancel" color="grey" onClick={onClose} disabled={saving} isLink />{" "}
          {saving && duplicatedScenario !== "none" ? (
            <div className={styles.savingText}>
              <span>{progressText || "Creating scenario copy... This could take a minute or two."}</span>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}
