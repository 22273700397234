import {log} from "@shared/lib/debug-provider";
import {isWebWorker} from "browser-or-node";
import {io} from "socket.io-client";

import type {Middleware} from "@reduxjs/toolkit";
import type {AppActionMeta, AppReduxStore} from "@state/store";
import type {WebsocketEventPayloads} from "@state/websockets";
import type {ClientRootState} from "./client-store";

export const socket = io(`/${isWebWorker ? "worker" : "main"}`, {transports: ["websocket"], path: "/api/ws/"});

export type ClientWebsocketEventHandler<K extends keyof Partial<WebsocketEventPayloads>> = (
  ...args: WebsocketEventPayloads[K]
) => void;

export type ClientWebsocketListener<K extends keyof Partial<WebsocketEventPayloads>> = (
  store: AppReduxStore,
) => ClientWebsocketEventHandler<K>;

export type ClientWebsocketListeners = {
  [K in keyof Partial<WebsocketEventPayloads>]: ClientWebsocketListener<K>;
};

export const typeWebsocketListeners = <T extends ClientWebsocketListeners>(listeners: T) => listeners;

let store: AppReduxStore | null = null;
let listeners: ClientWebsocketListeners | null = null;

export const initWebsocketListeners = (storeInstance: AppReduxStore, ...listenersLists: ClientWebsocketListeners[]) => {
  store = storeInstance;
  listeners = {};
  for (const listenersList of listenersLists) {
    Object.assign(listeners, listenersList);
  }

  for (const [eventName, listenerMaker] of Object.entries(listeners)) {
    socket.on(eventName, listenerMaker(store));
  }
};

export function pauseListener(eventName: keyof WebsocketEventPayloads) {
  socket.off(eventName);
}

export function resumeListener(eventName: keyof WebsocketEventPayloads) {
  const listener = listeners?.[eventName];
  if (store && listener) {
    socket.on(eventName, listener);
  } else {
    console.error(
      "Tried to resume a listener but no store seem to have been previously set - were the socket listeners initialized in the first place?",
    );
  }
}

export const websocketMiddleware: Middleware<{}> =
  (store) =>
  (next) =>
  ({type, payload, meta}) => {
    const newMeta: AppActionMeta = {...(meta ?? {})};
    if (!newMeta.initialDispatcher) newMeta.initialDispatcher = "user";

    const state: ClientRootState = store.getState();
    // Do not sync events if we're looking at a version, if it's not marked as syncable, if it has been received from a sync or if it has already been synced to the server
    const actionNeedsToBeSynced =
      !state.global.version &&
      !!newMeta.sync &&
      !newMeta.hasBeenSyncedToServer &&
      newMeta.initialDispatcher !== "websocket";

    if (actionNeedsToBeSynced) {
      log("WebsocketMiddleware", `Dispatching WebSocket action to server: "${type}"`, {
        type,
        payload,
        meta,
      });
      socket.emit("sync", {instanceId: state.global.instanceId, type, payload});
      newMeta.hasBeenSyncedToServer = true;
    }

    next({type, payload, meta: newMeta});
  };
